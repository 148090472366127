import { Routes, Route } from "react-router-dom";
import NotFound from 'pages/NotFound';
import { PAGES } from "constants/constants";
import ThemeSelectorPage from "pages";
import NProgress from 'nprogress';
import { SetStateAction } from "react";
import PrivateRoute from "components/PrivateRoute";
import CallbackLogin from "pages/CallbackLogin";
import Login from "pages/Login";
import AppContextWrapper from "contexts";


NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
});

type MyProps = {
  // isOpen: boolean|undefined
  // setIsOpen: React.Dispatch<SetStateAction<boolean>>
}

// (window as any) = {
//   ...window,
//   compatioConfig: {
//     compatibleProducts: {
//       price: 1,
//       compareAtPrice: 0,
//       currency: "USD",
//       productImage: "https://demo-dev-magento2.compat.io/demo/pub/media/catalog/product/cache/207e23213cf636ccdef205098cf3c8a3/c/s/csrx1.easr.od0.bx_1.jpg",
//       productSku: '5435',
//       productName: `WINSTON NIMBUS 7WT 9'0"/BAUER SST7 ROD & REEL KIT`,
//       dcConfig: [
//         {
//           modelId: "64199974079e4bfa9a17130b9567e035",
//           details: {
//             title: "Outfit Yourself for Your Ride",
//             buttonText:"Build An Outfit",
//             description: "Browse helmets, gloves, shorts and other accessories",
//             imageUrl:"https://cdn.shopify.com/s/files/1/0522/3104/5298/files/SALSA_HighRES-8_1.png?v=1612205698"
//           }
//         },
//         {
//           modelId: "0d900a08ead140bb90f9b8effb130a26",
//           details: {
//             title: "Get your bike ready to ride",
//             buttonText:"Build A Bike",
//             description: "Browse bikes, pedals, shoes, helmets and other accessories",
//             imageUrl: "https://cdn.shopify.com/s/files/1/0522/3104/5298/files/SALSA_HighRES-8_1.png?v=1612205698"
//           }
//         }
//       ]
//     },
//     dcColorConfig:  {
//       colorCombination: "green"
//     },
//     magento: {
//       apiEndpointUrl: "https://demo-magento2.compat.io/demo/graphql",
//       baseUrl: 'https://demo-magento2.compat.io/demo/',
//       urlSuffix: '.html',
//       targetGraph: "Master",
//     }
//   }
// }

function App(props: MyProps) {
  // if(props.isOpen === false) return <></>

  return (
    // <BrowserRouter>
        <Routes>
          {/* paths */}
          <Route path={PAGES.LOGIN_CALLBACK} element={<CallbackLogin/>}/>
          <Route path={PAGES.LOGIN} element={<Login/>}/>
          <Route
            path={PAGES.CONFIGURE}
            element={
              <PrivateRoute isOpen={undefined} setIsOpen={() => {}}>
                <ThemeSelectorPage />
              </PrivateRoute>
            }
          />
          <Route path={PAGES.PREVIEW} element={
            <AppContextWrapper isOpen={undefined} setIsOpen={() => {}}>
              <ThemeSelectorPage />
            </AppContextWrapper>
          }/>
        
          <Route path="*" element={<NotFound/>} />
        </Routes>
    // </BrowserRouter>
  )
}

export default App;
