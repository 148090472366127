import { CART_INFO, RESULT_PARENT_ID, RESULT_PRDT_CATEGORY_NAME, RESULT_PRDT_MODEL_NO, RESULT_PRDT_PRICE, RESULT_PRDT_QTY, RESULT_PRDT_SKU, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_SPEC } from "constants/constants"
import { Dispatch, SetStateAction,  useState } from "react"
import { toast } from "react-toastify"
import { TCart, TCartItem } from "types/Cart"
import { TResultProduct, TRuntimeInstance, TRuntimeSpec } from "types"
import { setInstanceInput } from "api"
import Utils from "Services/Utils"
import AppSettings from "Settings/AppSettings"
import MagentoHelper from "helpers/Clients/Magento"
import ProductHelper from "helpers/ProductHelper"
import ShopifyHelper from "helpers/Clients/Shopify"

/**
 * Cart based context 
 */

const useCart = (
    setRuntimeSpec: Dispatch<SetStateAction<TRuntimeSpec | null>>, 
    loadModelSpec: (setSessionId?: boolean, iter?: number, isReset?: boolean, modelId?: string, variants?: string[]) => Promise<void>, 
    setDataLoading: Dispatch<SetStateAction<boolean>>,
    setCartInfo: Dispatch<SetStateAction<TResultProduct[]>>,
    modelId?: string
) => {
    const [cart, setCart] = useState<TCart>([])
    
    const addProductToCart = (product: TResultProduct, quantity: number, category: string, instance: TRuntimeInstance, categoryName?: string) => {
        if(!product[RESULT_PRDT_SKU]) return toast.error("SKU not found")

        // if product is already in cart
        if(Utils.findProductIndex(cart, product, category, instance[RUNTIME_INSTANCE_INSTANCE_ID]) !== -1) return
        setDataLoading(true)

        setCart((prev) => {
            const _prevCart = [...prev]
            setInstanceInput(category, [
                ...prev.filter((_product) => _product.category === category && _product.instanceId === instance[RUNTIME_INSTANCE_INSTANCE_ID])
                .reduce((acc: TCartItem[], _product) => {
                    for(let i = 0; i < _product.quantity; i++) {
                        acc.push(_product)
                    }
                    return acc
                }, [])
                .map(_product=> _product.sku), 
                product[RESULT_PRDT_SKU]
            ], instance[RUNTIME_INSTANCE_INSTANCE_ID])
            .then((res: any) => {
                setRuntimeSpec(Utils.formatRuntimeSpec(res[RUNTIME_SPEC])) 
                if(res[CART_INFO]?.length>0) setCartInfo(res[CART_INFO] ?? [])
            })
            .catch((err) => {
                setCart(_prevCart)
                toast.error(err.message)
                console.error(err)
            })
            .finally(() => setDataLoading(false))

            return [...prev, {...product, quantity: quantity, category, instanceId: instance[RUNTIME_INSTANCE_INSTANCE_ID], [RESULT_PRDT_CATEGORY_NAME]: categoryName ?? ""}]
        })
    }

    const updateProductQty = (product: TResultProduct, quantity: number, category: string, instanceId: string, apiCall: boolean = true) => {
        if(!product[RESULT_PRDT_SKU]) return toast.error("SKU not found")
        setDataLoading(true)

        setCart(prev => {
            if(apiCall) setInstanceInput(category, [
                ...prev.filter((_product) => _product.category === category && _product.instanceId === instanceId)
                .reduce((acc: string[], _product) => {
                    let _quantity = _product.sku !== product.sku ? _product.quantity : quantity
                    for(let i = 0; i < _quantity; i++) {
                        acc.push(_product.sku)
                    }
                    return acc
                }, []), 
                // ...[...Array(quantity)].map(() => product[RESULT_PRDT_SKU])
            ], instanceId)
            .then((res: any) => {
                setRuntimeSpec(Utils.formatRuntimeSpec(res[RUNTIME_SPEC])) 
                if(res[CART_INFO]?.length>0) setCartInfo(res[CART_INFO] ?? [])
            })
            .catch(() => {
                const variants = []
                const productSku = Utils.getClientProductSku()
                if(productSku) variants.push(productSku)
                loadModelSpec(true, 0, true, modelId, variants)
                // setCart(prevCart)
            })
            .finally(() => setDataLoading(false))

            return prev
        })
    }


    const removeProductFromCart = (product: TResultProduct, category: string, instanceId: string) => {
        // check if product already exists in cart add the quantity
        setCart((prev) => {
            const _prevCart = [...prev]
            const newCart = [...prev]
            
            const index = Utils.findProductIndex(_prevCart, product, category, instanceId)

            if(index === -1) return prev
            setDataLoading(true)

            newCart.splice(index, 1)
            
            setInstanceInput(
                category, 
                [
                    ...newCart.filter((_product) => _product.category === category && _product.instanceId === instanceId)
                    .reduce((acc: TCartItem[], _product) => {
                        for(let i = 0; i < _product.quantity; i++) {
                            acc.push(_product)
                        }
                        return acc
                    }, [])
                    .map(_product=> _product.sku)
                ],
                instanceId, 
                false, 
                false, 
                false
            )
            .then((res: any) => {
                setRuntimeSpec(Utils.formatRuntimeSpec(res[RUNTIME_SPEC])) 
                if(res[CART_INFO]?.length>0) setCartInfo(res[CART_INFO] ?? [])
            })
            .catch((err) => {
                setCart(_prevCart)
                toast.error(err.message)
            })
            .finally(() => setDataLoading(false))

            return newCart
        })

        
        // .finally(() => setDataLoading(false))
    }

    const getCartTotalPrice = () => {
        let total = 0;
        cart.forEach(product => {
            total += (product[RESULT_PRDT_PRICE] as number) * (product[RESULT_PRDT_QTY] ?? 1);
        });
        return total;
    }

    const generateCartFromProductData = async (
        cartProductIds: {categoryId: string, instanceId: string, categoryName: string, productIds: (string)[]}[],
        products: TResultProduct[]
    ) => {
        const productIdMap = Object.fromEntries(products.map((product) => ([product[RESULT_PRDT_SKU], product])))
        const productIds: (string)[] = Object.keys(productIdMap)
        let clientProducts: {[x: string]: any} = {}
        if(products.length > 0)
        if(Utils.isMagento()) {
            try{
                // if there is a client integration get Availability from 
                // and filter Compatio products
                clientProducts = await 
                    MagentoHelper.getProductsBySkus(productIds)
            }
            catch(error){
                // setCompatioProducts(res.products ?? [])
                console.debug("Client Inventory check failed, showing all products")
            }
        }
        else if(Utils.isShopify()) {
            try{
                // if there is a client integration get Availability from 
                // and filter Compatio products
                clientProducts = await 
                    ShopifyHelper.getCartProducts(products)
            }
            catch(error){
                // setCompatioProducts(res.products ?? [])
                console.debug("Client Inventory check failed, showing all products")
            }
        }
        const newCartValue: TCartItem[] = []
        cartProductIds.forEach(data => {
            const ids = new Set(data.productIds)

            ids.forEach((productId) => {
                if(productIds.includes(productId)) newCartValue.push({
                    ...productIdMap[productId], 
                    quantity: data.productIds.filter(_productId => productId === _productId).length, 
                    category: data.categoryId, 
                    instanceId: data.instanceId, 
                    [RESULT_PRDT_CATEGORY_NAME]: data.categoryName ?? "",
                    [RESULT_PRDT_MODEL_NO]: clientProducts[productId]?.modelNumber ?? productIdMap[productId]?.[RESULT_PRDT_MODEL_NO] ?? "",
                    ...(clientProducts[productId] ? clientProducts[productId] : [])
                })
            })
        })
        setCart(newCartValue)
        setCartInfo([])
    }

    return {
        cart,
        setCart,
        addProductToCart,
        removeProductFromCart,
        updateProductQty,
        getCartTotalPrice,
        generateCartFromProductData
    }
}

export default useCart