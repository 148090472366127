import { FaBars } from "react-icons/fa"
import { TTableHeaderControlButtonProps } from "types/components/Table"

const TableHeaderControlButton = ({disableHeaderControls, setHeaderControlOpen}: TTableHeaderControlButtonProps) => {
    if(disableHeaderControls) return null

    return (
        <button 
            type="button" 
            className={`sb3-aspect-sqaure sb3-h-8 sb3-w-8 sb3-rounded hover:sb3-bg-[#C5C6CC] focus:sb3-outline-none`} 
            onClick={() => setHeaderControlOpen(true)}
            >
                <FaBars className={`sb3-text-gray-dark sb3-m-auto`}/>
        </button>
    )
}

export default TableHeaderControlButton