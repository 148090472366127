import BorderlessButton from "components/Button/BorderlessButton"
import { ProductPriceString } from "components/Product/ProductPriceString"
import QuantityInput from "components/Product/QuantityInput"
import { PLACEHOLDER_IMAGE, RESULT_PRDT_CATEGORY, RESULT_PRDT_INSTANCEID, RESULT_PRDT_PRICE, RESULT_PRDT_SKU } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
// import { m } from "framer-motion"
import ProductHelper from "helpers/ProductHelper"
import { debounce } from "lodash"
import { useCallback } from "react"
import { FaRegTrashAlt, FaTrash, FaTrashAlt } from "react-icons/fa"
import { toast } from "react-toastify"
import Utils from "Services/Utils"
import { TResultProduct } from "types"
import { TCartItem } from "types/Cart"

type MyProps = {
    product: TCartItem, 
    isNextActive: boolean, 
    categoryId: string, 
    instanceId: string
    isQtyActive: boolean
}

const SubmodelSummaryItem = ({
    product, 
    isNextActive, 
    categoryId, 
    instanceId,
    isQtyActive
}: MyProps) => {
    const {
        removeProductFromCart,
        updateProductQty,
        setCart,
        cart,
        theme
    } = useAppContext()

    const debouncedQtyChange = useCallback(debounce((product: TResultProduct, quantity: number, category: string, instanceId: string, apiCall?: boolean) => updateProductQty(product, quantity, category, instanceId), 600), [product])

    const handleQtyChange = (quantity: number) => {
        if(!instanceId) return

        if(!product[RESULT_PRDT_SKU]) return toast.error("SKU not found")

        const prevCart = [...cart]

        // if product is already not in cart
        if(Utils.findProductIndex(prevCart, product, categoryId, instanceId) === -1) return 

        if(quantity <= 0) {
            return removeProductFromCart(product, categoryId, instanceId)
        }

        setCart((prev) => {
            const newCart = [...prev]

            const index = Utils.findProductIndex(prevCart, product, categoryId, instanceId)

            // if product is already in cart
            if(index === -1) {
                return newCart
            }

            const newProductVal = {...newCart[index]}

            newProductVal.quantity = quantity
            newCart[index] = newProductVal
            return newCart
        })
        // updateProductQty(product, quantity, categoryId, instanceId as string)
        
        debouncedQtyChange(product, quantity, categoryId, instanceId as string)
    }

    return (
        <div 
        // initial={{ maxHeight: "400px", opacity: 0}}
        // animate = {{maxHeight: "400px", opacity: 1}}
        // exit = {{maxHeight: 0, opacity: 0}}
         className={`sb3-w-full sb3-flex sb3-flex-wrap sb3-space-x-2 sb3-justify-between sb3-border-b last:sb3-border-0 sb3-py-2 ${Utils.isLinear(theme) && "sb3-bg-white sb3-p-2"}`}>
        
            <div className="sb3-w-14">
                <img 
                    src={!!ProductHelper.getProductImage(product) ? ProductHelper.getProductImage(product) : PLACEHOLDER_IMAGE} 
                    alt="Product" 
                    className="sb3-aspect-square sb3-h-auto sb3-max-w-full"
                />
            </div>

            <div className="sb3-w-8/12 sb3-flex-grow">
            <div className="sb3-flex sb3-justify-between">
                <div>
                    <p className="sb3-text-sm sb3-truncate ... sb3-max-w-max">
                        {ProductHelper.getProductName(product)}
                    </p>
                    <p className="sb3-text-xs sb3-text-gray-dark truncate ... sb3-max-w-max">
                        {ProductHelper.getProductDescription(product)}
                    </p>

                    <p className="sb3-font-bold sb3-text-sm">
                        <ProductPriceString
                            price={ProductHelper.getProductPrice(product)}
                            // compareAtPrice={product.compareAtPrice}
                            // currency={product.currency}
                        />
                    </p>
                </div>
                

                <div className="sb3-flex sb3-space-x-2 sb3-items-center">
                {
               isQtyActive && <div className="">
                        <QuantityInput
                            disableNext={(newQty) => isNextActive}
                            disablePrev={(newQty) => newQty <= 1}
                            quantity={ProductHelper.getProductQuantity(product)}
                            handleChange={(newQty) => handleQtyChange(newQty)}
                        />
                    </div>
                }
                <div className='sb3-text-right sb3-flex sb3-justify-between sb3-items-center'>
                    
                    <BorderlessButton onClick={() => removeProductFromCart(product, product[RESULT_PRDT_CATEGORY], product[RESULT_PRDT_INSTANCEID])} className="!sb3-p-0  sb3-min-w-min sb3-min-h-min !sb3-text-gray-dark"><FaRegTrashAlt/></BorderlessButton>
                </div>

                </div>
               
            </div>
                

                
            </div>
        </div >
    )
}

export default SubmodelSummaryItem