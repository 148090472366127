import FillButton from "components/Button/FillButton"
import ProductHelper from "helpers/ProductHelper"
import { ProductPriceString } from "./ProductPriceString"
import { useAppContext } from "contexts/AppContext"
import {  CATEGORY_NAME, MODEL_DATA, PLACEHOLDER_IMAGE, RESULT_PRDT_CATEGORY_ID, RESULT_PRDT_DESC, RESULT_PRDT_NAME, RESULT_PRDT_OPTIONS, RESULT_PRDT_PRICE,  RESULT_PRDT_SKU,  RUNTIME_INSTANCE_INSTANCE_ID } from "constants/constants"
import QuantityInput from "./QuantityInput"
import { TProductProps } from "./types"
import ProductStockStatus from "./ProductStockStatus"
import BorderedButton from "components/Button/BorderedButton"
import Utils from "Services/Utils"
import { isArray } from "lodash"
import { useEffect, useState } from "react"
import { TResultProduct } from "types"
import ReactSelect from "react-select"
import TooltipInfo from "components/TooltipInfo"
import ProductDescriptionTooltip from "components/ProductDescriptionTooltip"

const ProductHorizontal = ({
    category,
    product,
    selected,
    instance,
    showQtyInput,
    quantity,
    maxQty,
    buttonLabel,
    type,
    handleProductClick,
    handleQtyChange
}: TProductProps) => { 
    const {
        addProductToCart,
        removeProductFromCart,
        dataLoading,
        theme,
        setImagePreview
    } = useAppContext()

    const [selectedVariant, setSelectedVariant] = useState<TResultProduct|undefined>()

    useEffect(() => {
        if (isArray(product[RESULT_PRDT_OPTIONS]) 
            && product[RESULT_PRDT_OPTIONS]?.length > 0 ){
            setSelectedVariant(product[RESULT_PRDT_OPTIONS][0])
        }
    }, [])
    
    const options = product[RESULT_PRDT_OPTIONS]
        // ?.sort(props.sort ? Utils.sortChoices(true, SORT.SEPERATE_ASC, (item: any) => item[CHOICE_NAME], (item: any) => item[CHOICE_IS_DISABLED]) as any : undefined)
        ?.reduce((acc: {value: string, label: string}[], choice) => {
            acc.push({value: choice[RESULT_PRDT_SKU], label: choice.variantTitle ?? choice[RESULT_PRDT_NAME]})
            return acc
        }
    , [])

    const hasOptions = isArray(product[RESULT_PRDT_OPTIONS]) 
        && product[RESULT_PRDT_OPTIONS]?.length > 1 
    
    const productImage = ProductHelper.getProductImage(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])
    
    return (
        <div className={`sb3-bg-white sb3-w-full sb3-flex sb3-space-x-5 sb3-border-b sb3-py-10 sb3-px-2`}>
        <img onClick={() => setImagePreview(!!productImage ? productImage : PLACEHOLDER_IMAGE)} src={!!productImage ? productImage : PLACEHOLDER_IMAGE} alt="Product" className="sb3-aspect-square sb3-w-32 sb3-max-w-full sb3-object-contain"/>
    
        <div className="sm:sb3-flex sb3-justify-between lg:sb3-space-x-2 sb3-flex-grow sb3-max-w-full">
            <div className="sb3-min-w-0 sb3-overflow-hidden sb3-flex-grow sb3-space-y-1">
                {
                    product[RESULT_PRDT_OPTIONS] && hasOptions
                    && <span className="sb3-text-xs sb3-text-gray-extraDark">{product[RESULT_PRDT_OPTIONS].length} options</span>
                }
    
                {!Utils.isShopify() && <p title={ProductHelper.getProductPrimaryTitle(product)} className="sb3-text-gray-extraDark sb3-line-clamp-2 sb3-break-all">{ProductHelper.getProductPrimaryTitle(product)}</p>}

                <button 
                type="button" 
                className="sb3-text-base sb3-mt-2 sb3-text-left sb3-line-clamp-2 sb3-border-0 hover:sb3-bg-transparent hover:sb3-border-0 hover:sb3-text-black focus:sb3-text-black focus:sb3-bg-0 focus:sb3-border-transparent focus:sb3-outline-none sb3-break-words" 
                onClick={() => handleProductClick(selectedVariant ?? product)}
                title={ProductHelper.getProductSecondaryTitle(product)}>
                    {ProductHelper.getProductSecondaryTitle(product)}
                </button>
    
                {
                    isArray(product[RESULT_PRDT_OPTIONS]) 
                    && product[RESULT_PRDT_OPTIONS]?.length > 1 
                    && <ReactSelect
                        value={options?.filter((item) => (selectedVariant)?.[RESULT_PRDT_SKU] === item.value)}
                        options={options}
                        onChange={(selection) => setSelectedVariant(product[RESULT_PRDT_OPTIONS]?.find(pr => pr[RESULT_PRDT_SKU] === selection?.value))}
                        className="sb3-max-w-96"
                        menuPosition="fixed"
                        styles={{
                            control: (provided, state) => ({
                            ...provided,
                                borderRadius: 0,
                                minWidth: 100,
                                background: "#F9F9F9"
                            })
                        }}
                    />
                }
    
                <span className="sb3-text-sm sb3-text-gray-extraDark sb3-line-clamp-1">{category[MODEL_DATA][CATEGORY_NAME]}</span>
    
                <span className="sb3-flex sb3-items-center sb3-max-w-full sb3-text-sm sb3-space-x-2">
                    <ProductStockStatus product={selectedVariant ?? product}/>
                </span>
    
                {(selectedVariant?.[RESULT_PRDT_DESC] || product[RESULT_PRDT_DESC]) && <ProductDescriptionTooltip message={selectedVariant?.[RESULT_PRDT_DESC] ?? product[RESULT_PRDT_DESC]}/>}
            </div>
    
            <div className='lg:sb3-text-right sm:sb3-pl-2 sb3-space-y-2 sb3-bg-white sb3-shrink-0'>
                <p className="sb3-font-bold">
                    <ProductPriceString
                        price={ProductHelper.getProductPrice(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])}
                        // compareAtPrice={product.compareAtPrice}
                        // currency={product.currency}
                /></p>
                <div className="sb3-w-max sb3-space-y-2">
                    {
                        !selected && 
                        <BorderedButton disabled={!showQtyInput || dataLoading} onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] && addProductToCart(selectedVariant ?? product, 1, product[RESULT_PRDT_CATEGORY_ID], instance, category[MODEL_DATA][CATEGORY_NAME])} className="!sb3-px-6 sb3-w-max">{
                            buttonLabel ?? "ADD TO BUILD"
                        }</BorderedButton>
                    }
    
                    {
                        selected && (maxQty ?? 1) > 1 && quantity && 
                        <QuantityInput
                            quantity={quantity}
                            handleChange={handleQtyChange}
                            disablePrev={(qty: number) => qty === 1}
                            disableNext={() => !showQtyInput}
                        />
                    }
    
                    {
                        selected && <BorderedButton disabled={dataLoading} onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] && removeProductFromCart(selectedVariant ?? product, product[RESULT_PRDT_CATEGORY_ID], instance[RUNTIME_INSTANCE_INSTANCE_ID])} className="!sb3-px-6 sb3-w-full !sb3-text-danger !sb3-border-danger">{
                            "REMOVE"
                        }</BorderedButton>
                    }
                </div>
            </div>
        </div>
    </div>
    )
}

export default ProductHorizontal