import Utils from "Services/Utils"
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS,  MODEL_DYNAMIC_FIELDS, RESULT_PRDT_CATEGORY, RESULT_PRDT_INSTANCEID, RESULT_PRDT_QTY, RESULT_PRDT_SKU, RUNTIME_INSTANCE_MAX_QUANTITY, THEME_SUBTITLES } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import CompatioAnalytics from "helpers/CompatioAnalyticsSb3"
import ProductHelper from "helpers/ProductHelper"
// import { AnimatePresence } from "framer-motion"
import CartItem from "./CartItem"
import FillAnchor from "components/Button/FillAnchor"
import { TCartItem } from "types/Cart"
import SummaryItem from "components/Header/SummaryItem"
import SubmodelSummaryItem from "./SummaryItem"

type MyProps = {
    categoryIds: string[]
}

const SubmodelSummary = ({categoryIds}: MyProps) => {
    const {
        cart,
        getCartTotalPrice,
        runtimeSpec,
        builderSpec,
        handleReset,
        setLoadingToCart,
        setIsOpen,
        theme,
        categoryLabels,
        modelId,
        modelSpec
    } = useAppContext()
    
    const handleCartAdd = () => {
        let dataFilterdForAnalytic: any = [];
        cart.forEach(cartItem => {
            dataFilterdForAnalytic.push({
                baseFinalProductID: Utils.getClientProductSku(),
                baseParentProductID: Utils.getClientProductSku(),
                baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURIComponent(window.compatioConfig.compatibleProducts.productName),
                finalProductID: cartItem[RESULT_PRDT_SKU],
                parentProductID: cartItem[RESULT_PRDT_SKU],
            });
        });

        CompatioAnalytics.track(
            CompatioAnalytics.events.BuilderClick,
            { products: dataFilterdForAnalytic },
            'SmartBuilder3',
            undefined,
            'V1',
            modelSpec?.project.ISRName,
            modelId
        );

        CompatioAnalytics.trackNew(
            CompatioAnalytics.events.BuilderClick,
            { products: dataFilterdForAnalytic },
            'SmartBuilder3',
            undefined,
            'V1',
            modelSpec?.project.ISRName,
            modelId
        )

        setLoadingToCart(true)
    }
    // h-[750px] min-h-max max-h-[min(calc(100vh-_140px),_750px)]

    const newCart = cart.filter(cartItem => categoryIds.includes(cartItem.catgeory_id))

    return (
        <div className={`sb3-text-left sb3-flex sb3-flex-col sb3-justify-between sb3-rounded-md sb3-min-w-[270px] sb3-min-h-min`}>
            <div className="sb3-flex sb3-flex-col sb3-flex-grow">
                {/* Cart Products */}
                <div className="sb3-flex-grow">
                    {/* <AnimatePresence> */}
                    {
                        newCart.map((product, key) => {
                            const isNextActive = (runtimeSpec?.[ProductHelper.getProductInstanceId(product)]?.[MODEL_DYNAMIC_FIELDS][ProductHelper.getProductCategoryId(product)][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) <= (Utils.getSelectedProductsFromCategory(cart, product[RESULT_PRDT_CATEGORY], product[RESULT_PRDT_INSTANCEID])?.reduce((acc, product) => acc + product.quantity ,0) ?? 0)
                            
                            return (
                                <SubmodelSummaryItem
                                    key = {key}
                                    product={product}
                                    categoryId={product[RESULT_PRDT_CATEGORY]}
                                    instanceId = {product[RESULT_PRDT_INSTANCEID]}
                                    isNextActive={isNextActive}
                                    isQtyActive={(runtimeSpec?.[product[RESULT_PRDT_INSTANCEID]]?.[MODEL_DYNAMIC_FIELDS][product[RESULT_PRDT_CATEGORY]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > 1}
                                />
                            )
                        })
                    }
                    {/* </AnimatePresence> */}
                </div>
            </div>
        </div>
    )
}

export default SubmodelSummary