import TableHeaders from "./components/TableHeaders";
import { conditionalStyle } from "./utils";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import useTable from "hooks/useTable";
import Dropdown from "components/Dropdown";
import { TTableData, TTableProps } from "types/components/Table";
import TableSkeletonElement from "./components/TableSkeletonElement";
import TableBodySkeletonElement from "./components/TableBodySkeletonElement";
import { PLACEHOLDER_IMAGE } from "../../constants/constants";
import IndeterminateCheckbox from "components/Table/components/IndeterminateCheckbox";
import Paginator from "./components/PaginatorWithApi";

/**
 * Table component
 * 
 * @param {TTableHeader} headers
 * @param {string} headers.name - label for the table header
 * @param {string} headers.column - map to data key
 * @param {{[x: string]: any}[]} data
 * @param {object} options
 * @param {boolean} selectable - checkbox for rows?
 * @param {boolean} enableColumnDrag - Rearrange columns?
 * @returns 
 * 
 * ----------------------------------------------------------------
 * 
 * Additional header parameters
 * 
 * @param {string} className - addtional classname for columns(applied to both header and data)
 * @param {boolean} sortable - is column sortable?
 * 
 * ----------------------------------------------------------------
 */

const Table = <T extends {[x:string]: any}>({
    columns,
    data,
    options,
    className,
    loading,
    dataLoading,
    pageSize,
    totalRecords,
    selectedAllCondition,
    isSelectedCondition,
    handleSelectAll,
    handleSelect,
    updateFilters,
    dataCallback,
    fetchData,
    // handleColumnDrag,
    // appData,
    // recordPerPage,
    // setRecordPerPage,
    // handlePageChange,
    // handleSortByColumn,
    // sort,
    // handleViewLog,
    // NoDataComponent,
    // handleDelete,
    // page,
}: TTableProps<T>) => {
    const {
        headers,
        rows,
        filters,
        headerControlOpen,
        defaultHeaders,
        setHeaderControlOpen,
        setHeaders,
        updateLocalFilters,
        setRows,
        setFilters,
        handleSort,
        handlePageChange,
    } = useTable({columns, data, options})

    const [tableWidth, setTableWidth] = useState<number|string>("auto") // used to set accordion container width
    const tableContainerRef = useRef<HTMLDivElement>(null)

    const [activeAccordion, setActiveAccordion] = useState<number|null>(null)

    useLayoutEffect(() => {
        // set width of table to use for accordion container width
        function handleResize() {
            setTableWidth(tableContainerRef?.current?.clientWidth ?? "auto");
        }
        tableContainerRef?.current && handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    useEffect(() => {
        updateFilters?.(filters ?? {})
    }, [filters])

    useEffect(() => {
        setActiveAccordion(null)
    }, [rows])

    if (loading) {
        return (
          <div>
            <TableSkeletonElement />
          </div>
        );
    }

    // fetches data using call back function
    const getData = (item: TTableData<T>) => dataCallback ? dataCallback(item) : item

    // condition to check if row has accordion
    const checkIfRowHasAccordion = (item: T) => options?.accordion?.condition && !!options?.accordion?.condition(item)
    
    // striped row
    const getRowBgColor = (index: number, selected: number) => {
        if(selected === 1) return "sb3-bg-selected"
        return (index%2===0 || !options?.striped) ? "sb3-bg-white": "sb3-bg-[#F4F5F7]"
    }

    const AccordianComponent = options?.accordion?.component ?? <></>

    return (
        <>
    <div ref={tableContainerRef} className={`table-container sb3-min-h-[500px] sb3-overflow-auto sb3-border -sb3-z-20 ${className}`}>
        <div className={`sb3-w-full`} style={{height: options?.height ?? "auto"}}>
            <table
                border={0}
                cellPadding={0}
                className={`sb3-w-full sb3-text-gray-text sb3-align-top sb3-whitespace-nowrap sb3-table-auto`}
            >
                <TableHeaders 
                    handleSave={setHeaders}
                    defaultHeaders={defaultHeaders}
                    headerControlOpen={headerControlOpen}
                    options={options}
                    headers={headers}
                    setHeaderControlOpen={setHeaderControlOpen}
                    rows={rows}
                    handleSelectAll={handleSelectAll}
                    selectedAllCondition={selectedAllCondition}
                />

                <tbody className={`sb3-z-10 sb3-relative`}>
                    {
                        (dataLoading || !rows) && 
                            <TableBodySkeletonElement/>
                    }
                    {
                        !dataLoading && (rows && rows.length === 0) && <tr>
                        <td colSpan={headers.length}>
                                <div style={{width: tableWidth}} className={`sb3-text-xs sb3-p-2 sb3-text-center sb3-sticky sb3-left-0 sb3-w-full sb3-animate-pulse`}>
                                    No Data Found.
                                </div>
                            </td>
                        </tr>
                    }
                    {
                        !dataLoading && rows?.length > 0 && rows.map((deltaItem: TTableData<T>, index: number) => {
                            const item: any = getData(deltaItem)
                            const zIndex = 1000-index
                            const selected = isSelectedCondition ? isSelectedCondition(deltaItem) : 0
                            const backgroundColor = getRowBgColor(index, selected)
                            const dropdownOptions = options?.dropDownCallback ? options?.dropDownCallback(deltaItem) : []
                            
                            if(!item) return <></>
                            
                            return(
                                <React.Fragment key={index} >
                                    <tr
                                        className={`sb3-h-1 sb3-border-y sb3-border-[#DFE0E6]`}
                                    >
                                        {
                                            options?.selectable &&
                                            <td style={{zIndex, height: "inherit"}} className={`sb3-text-[#172B4D] sb3-sticky sb3-left-0 sb3-z-10 ${backgroundColor}`}>
                                                <div style={{paddingLeft: options?.firstColPaddingLeft ?? 0}} className={`sb3-w-12 sb3-text-center`}>
                                                    <IndeterminateCheckbox checked={selected === 1 && "checked"}  indeterminate={selected === 0}  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelect && handleSelect(e, deltaItem)}/>
                                                </div>
                                            </td>
                                        }
                                        {
                                            headers?.filter((header) => header.display).map((header, key) => {
                                                const image = (!options?.image?.(item) || options?.image?.(item) === "") ? PLACEHOLDER_IMAGE : options?.image?.(item)

                                                return (
                                                <td 
                                                key={key} 
                                                style={{...conditionalStyle(header.options), height: "inherit"}}
                                                className={`sb3-text-[#172B4D]`}>
                                                    
                                                        <div 
                                                        className={`${header.options?.sticky?.[0] ? "sb3-shadow-r" : ""} sb3-w-full sb3-h-full sb3-pl-2 sb3-pr-4 sb3-py-2.5 sb3-flex sb3-space-x-2 sb3-items-center  ${header.options?.callbackClassNames && header.options.callbackClassNames(item)} ${backgroundColor} ${header.className ?? ""}`}>
                                                            {
                                                                header.options?.callbackComponent && header.options?.callbackComponent(item)
                                                            }   
                                                            {
                                                                !header.options?.callbackComponent &&
                                                                <span className={`sb3-leading-7 sb3-truncate sb3-max-w-full`}>{item[header.column] ?  `${item[header.column]}` : "-"}</span>
                                                            }
                                                            
                                                        </div>
                                                    
                                                </td>
                                            )})
                                        }
                                        {
                                        !options?.disableRowOptions && <td style={{zIndex, height: "inherit"}}
                                        className={`sb3-text-[#172B4D] sb3-sticky ${backgroundColor}  ${options?.accordion ? "sb3-right-[40px]" : "sb3-right-0"}`}
                                        >
                                        </td>
                                        }
                                    </tr>
                                    {
                                        activeAccordion === index && 
                                        <tr className={`sb3-relative`}>
                                            <td colSpan={headers.length + 3}>
                                                <div className={`sb3-sticky sb3-left-0 sb3-shadow-sm`} style={{maxWidth: tableWidth}}> 
                                                    {
                                                        checkIfRowHasAccordion(item) && 
                                                        <AccordianComponent {...options?.accordion?.callbackProps(deltaItem)}/>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

    </div>
    {   
        options?.footer &&
        <div className="sb3-py-2">
        <div className={`sb3-flex sb3-justify-end sb3-items-center sb3-space-x-3 sb3-flex-wrap`}>
            <div className={`sb3-flex sb3-items-center sb3-space-x-3`}>
                <span>Show</span>
                <select
                    className={`sb3-border focus:sb3-outline-none sb3-p-1`}
                    style={{ width: "80px" }}
                    aria-label="Records on page select"
                    value={options.pagination === "manual" ? (filters?.limit ?? 100) :(pageSize)}
                    onChange={(e) => {
                        updateFilters && updateFilters({ limit: parseInt(e.target.value) ?? undefined })
                        updateLocalFilters({ limit: parseInt(e.target.value) ?? undefined })
                    }}
                >
                    <option value={undefined}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={500}>500</option>
                    <option value={10000}>All</option>
                </select>
            </div>
            <Paginator
                onPageChange={handlePageChange}
                recordPerPage={options.pagination === "manual" ? (filters?.limit ?? 100) : (pageSize ?? 10)}
                totalRecords={totalRecords ?? 1}
                presentPage={filters?.page ? filters.page : 1}
            />
        </div>
    </div>
    }
   
</>
    )
}

export default Table