import SkeletonElement from "components/Table/components/SkeletonElement"
import { PRDT_DISPLAY_TYPES } from "constants/constants"

type MyProps = {
    type?: string
}

const ProductSkeleton = ({type}: MyProps) => { 
    return (
        <div className={`sb3-w-full sb3-space-x-2 sb3-border-b sb3-py-5 ${type === PRDT_DISPLAY_TYPES.HORIZONTAL ? "sb3-flex" : "sb3-border sb3-rounded sb3-p-2"}`}>
            <SkeletonElement type={`sb3-aspect-square ${type === PRDT_DISPLAY_TYPES.HORIZONTAL ? "sb3-w-32" : "sb3-w-full"} sb3-rounded-0`}/>
            

            <div className="sb3-flex-grow">
                <SkeletonElement type="sb3-py-2 text50" />

                <SkeletonElement type="text sb3-mt-2 sb3-max-w-[200px] sb3-py-3"/>
                <SkeletonElement type="sb3-py-2 text25 sb3-mt-2" />
            </div>

            <div className='sb3-flex sb3-flex-col sb3-items-end sb3-space-y-2'>
                <SkeletonElement type="sb3-py-2 text25 sb3-mt-2" />
                <SkeletonElement type="text sb3-mt-2 sb3-py-5 sb3-px-16" />
            </div>
        </div>
    )
}

export default ProductSkeleton