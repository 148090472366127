import ReactSelect, { MultiValue, SingleValue, StylesConfig } from "react-select";
import Title from "../Header/Title";
import { TChoice } from "types";
import { CHOICE_ID, CHOICE_IS_DISABLED, CHOICE_NAME, PAGE_LAYOUT, PAGE_LAYOUTS, SORT } from "constants/constants";
import Utils from "Services/Utils";
import NodeHelper from "helpers/NodeHelper";
import { useAppContext } from "contexts/AppContext";

type TOption = {
    label: string, value: string, disabled: boolean
}

type MyProps = {
    name: string
    value?: TChoice[]
    onChange: (value?: string[]|string) => void
    disabled?: boolean
    required?: boolean
    items?: TChoice[]
    isFilter?: boolean
    isMulti?: boolean
    description?: string
    info?: string
    sort?: boolean
}
const Select = (props: MyProps) => {
  const {activePage} = useAppContext()

  const valuesIds = props.value ? NodeHelper.getChoicesIds(props.value) : []

  const colourStyles: StylesConfig<TOption> = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.disabled ? '#9e9e9e'
        : isSelected
          ? 'white'
          : 'black',
        cursor: 'default',
      };
    },
    control: (provided, state) => ({
      ...provided,
        borderRadius: 0,
        minWidth: 100
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      padding: 0,
  }),
  };

  const options: TOption[]|undefined = props.items
  ?.sort(
    // (props.sort || activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING) ? 
    Utils.sortChoices(true, props.sort ? SORT.SEPERATE_ASC : SORT.SEPERATE, (item: any) => item[CHOICE_NAME], (item: any) => item[CHOICE_IS_DISABLED]) as any 
    // : undefined
  )
  .reduce((acc: {value: string, label: string, disabled: boolean}[], choice) => {
      acc.push({value: choice[CHOICE_ID], label: choice[CHOICE_NAME], disabled: !!choice[CHOICE_IS_DISABLED]})
      return acc
  }
  , [])

  const allDisabled = props.items?.length === 0 || props.items?.every((choice: TChoice) => choice[CHOICE_IS_DISABLED]) || props.disabled

  const handleChange = (value: MultiValue<TOption> | SingleValue<TOption>) => {
    if(props.isMulti) props.onChange((value as MultiValue<TOption>).map(value => value.value))
    else props.onChange((value as SingleValue<TOption>)?.value)
  }

  return (
    <div className="sb3-space-y-2">
        <Title title={props.name} required={props.required} description={props.description} info={props.info}/>
        <div 
          className={activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING ? '!sb3-w-60' : 'sb3-w-full'}
        >
          
          <ReactSelect 
            value = {options?.filter((item) => valuesIds.includes(item.value))}
            isDisabled={allDisabled} 
            options={options} 
            onChange={(selection) => !props.disabled && handleChange(selection)} 
            styles={colourStyles}
            isMulti={props.isMulti}
            menuPosition="fixed"
            />
        </div>
    </div>
  )  
};

export default Select