import { conditionalStyle } from "../utils"
import TableHeaderControlModal from "./TableHeaderControlModal"
import TableHeaderControlButton from "./TableHeaderControlButton"
import IndeterminateCheckbox from "./IndeterminateCheckbox"
import { TTableHeaderProps } from "types/components/Table"
import TooltipInfo from "components/TooltipInfo"


/**
 * Common table header with header management option
 * 
 * @param param0 
 * @returns 
 */
export default function TableHeaders ({
    options, 
    headers, 
    setHeaderControlOpen, 
    headerControlOpen, 
    handleSave,
    rows,
    handleSelectAll,
    selectedAllCondition,
    defaultHeaders}: TTableHeaderProps) {

    const selected = !options?.disableheaderSelectAll && selectedAllCondition && selectedAllCondition(rows)

    return (
        <>
           <thead className={`sb3-sticky sb3-top-0 sb3-z-[1001] sb3-text-[#6B778C]`}>
            <tr>
            {
                options?.selectable &&
                <th className={`${options?.headerClassName ?? ""} sb3-bg-[#DFE0E6] sb3-sticky sb3-left-0 sb3-h-full`}>
                    <div style={{paddingLeft: options?.firstColPaddingLeft ?? 0}} className={`${options?.headerClassName ?? ""} sb3-w-12 sb3-text-center sb3-max-h-min`}>
                    {
                        !options?.disableheaderSelectAll &&
                        <IndeterminateCheckbox checked={selected === 1 && "checked"} indeterminate={selected === 0} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectAll && handleSelectAll(e, rows)}/>
                    }
                    </div>
                </th>
            }
            {
                headers?.filter((header) => header.display).map((header, key) => (
                    <th key={key} className={`${options?.headerClassName ?? ""} sb3-bg-[#DFE0E6]`}
                        style={conditionalStyle(header.options)}
                    >
                        <div 
                        style={{width: header.options?.width ?? "auto"}} 
                        className={`sb3-py-3 sb3-flex sb3-space-x-2 sb3-items-center sb3-px-2 sb3-pr-6
                            ${options?.headerClassName ?? ""} 
                            ${header.options?.sticky?.[0] ? "sb3-shadow-r" : ""} 
                            ${header.className ?? ""}`}
                        >
                            <h3 className="sb3-text-base">{header.name}</h3>
                            {
                                (!options?.disableSort && header.sortable) && 
                                <i className={`fas fa-sort sb3-text-xs`} />
                            }
                            {
                                header.options?.info && 
                                <TooltipInfo message={header.options.info}/>
                            }
                        </div>
                    </th>
                ))
            }
            {
                !options?.disableRowOptions && <th className={`${options?.headerClassName ?? ""} sb3-bg-[#DFE0E6] sb3-sticky sb3-right-0 sb3-rel sb3-text-center sb3-z-10`}>
                    <span className={`sb3-absolute sb3-top-0 sb3-right-full sb3-h-full sb3-bg-gradient-to-r sb3-w-[100px] sb3-from-transparent sb3-pointer-events-none ${options?.headerStickyToClassName ?? "sb3-to-[#DFE0E6]"}`}></span>
                    { !options?.accordion && 
                        <TableHeaderControlButton
                            disableHeaderControls={options?.disableHeaderControls}
                            setHeaderControlOpen={setHeaderControlOpen}
                    />
                    }
                </th>
            }
            { 
            options?.accordion && <th className={`${options?.headerClassName ?? ""} sb3-bg-[#DFE0E6] sb3-sticky sb3-right-0 sb3-rel sb3-text-center sb3-z-10 sb3-max(40px,100%)`}>
                {
                    !options?.disableHeaderControls && 
                    <TableHeaderControlButton
                        disableHeaderControls={options?.disableHeaderControls}
                        setHeaderControlOpen={setHeaderControlOpen}
                    />
                }
            </th>
            }
            </tr>
        </thead>
        {
            (!options?.disableHeaderControls && headerControlOpen) && 
            <TableHeaderControlModal
                headers={headers}
                handleSave={handleSave}
                defaultHeaders = {defaultHeaders}
                headerControlOpen={headerControlOpen}
                setHeaderControlOpen={setHeaderControlOpen}
            /> 
        }
    </>
    )
}