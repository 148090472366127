type MyProps = {
    title: string,
    description: string|number
}
const SummaryItem = ({title, description}: MyProps) =>
    (
        <div className="sb3-flex-grow">
            <div className="sb3-overflow-hidden sb3-text-ellipsis sb3-leading-4 sb3-w-full -sb3-mr-5 sb3-text-xs sb3-text-gray-600">
                {title}
            </div>
            <div
                className="sb3-overflow-hidden sb3-text-black sb3-text-ellipsis sb3-text-sm sb3-font-medium sb3-leading-5 sb3-tracking-tight sb3-w-full -sb3-mr-5 sb3-mt-0.5">
                {description}
            </div>
        </div>
    );

export default SummaryItem;