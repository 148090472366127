import { get as _get } from 'lodash';

const CompatibleWithHelper = {
  keyMap: {
    compatibleWith: 'compatibleWith',
    sourceMerchantProductVariantID: 'sourceVariantID',
    sourceCompatioCategoryId: 'sourceCatId',
    productPosition: 'prodPos',
    categoryPosition: 'catPos',
    openBlankBuilder: 'blank',
    modelId: 'modelId',
  },

  isOpenBlankBuilder: function() {
    const hashObject = this.getUrlHashObject();

    if (hashObject.hasOwnProperty(this.keyMap.openBlankBuilder))
      return hashObject[this.keyMap.openBlankBuilder];
    return false;
  },

  /**
   * Is the current url a "target compatible product"
   *
   * @returns {boolean}
   */
  isCurrentPageTargetProduct: function() {
    const hashObject = this.getUrlHashObject();

    return hashObject.hasOwnProperty(this.keyMap.compatibleWith);
  },

  getByKey: function(key: string) {
    return _get(this.getUrlHashObject(), key);
  },

  /**
   * Parses multiple parameters in a url hash and returns them as an object
   *
   * Example url: https://example.dev/product.html#compatible-with=SKU123%26%3DABC&someOtherValue=thisValue
   *
   * Object that will be returned:
   *
   * {
   *   compatible-with: "SKU123&=ABC",
   *   someOtherValue: "thisValue"
   * }
   *
   * @returns {{}}
   */
  getUrlHashObject: function() {
    const hashObject: {[x: string]: string} = document.location.hash
      .substr(1) // Remove preceding #
      .split('&')
      .map(v => v.split('='))
      // Merge array into object
      .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

    Object.keys(hashObject).forEach(key => {
      hashObject[key] = decodeURIComponent(hashObject[key]);
    });
    return hashObject;
  },
};

export default CompatibleWithHelper;
