import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_TEMPLATE, CUSTOMIZATION_THEME_SETTINGS, THEMES, THEME_BACKGROUND } from "constants/constants"
import { useSearchParams } from "react-router-dom"
import { useAppContext } from "contexts/AppContext"
import { useEffect } from "react"
import { lazy } from "react"
import AppSettings from 'Settings/AppSettings'
import { FcDataConfiguration } from "react-icons/fc";

// themes
// const CumminsTheme = lazy(() => import('layouts/Cummins'))
// const ApplicoTheme = lazy(() => import('layouts/ApplicoTheme'))
// const ConfiguratorTheme = lazy(() => import('layouts/Configurator'))
import CumminsTheme from 'layouts/Cummins'
import ApplicoTheme from 'layouts/ApplicoTheme'
import ConfiguratorTheme from 'layouts/Configurator'
import { ToastContainer } from "react-toastify"
import AuthManager from "Services/AuthManager"
import TestTheme from "layouts/TestTheme"
import LinearTheme from "layouts/LinearTheme"
import LoadingComponent from "components/Loader"

  
const ThemeSelector = () => {
    const [URLSearchParams, _] = useSearchParams()
    const {theme, setTheme, builderSpec, uiDefn, isPreview, isOpen} = useAppContext()

    useEffect(() => {
        AuthManager.removePathname()
        AuthManager.removeQueryParams()
        if(URLSearchParams.get('theme')) return setTheme(URLSearchParams.get('theme'))
        if(AppSettings.THEME_FROM_BUILDER && builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_TEMPLATE] && 
            Object.values(THEMES).includes(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_TEMPLATE])) return setTheme(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_TEMPLATE] ?? THEMES.LINEAR)
    }, [URLSearchParams, uiDefn, builderSpec, setTheme])

    if(isPreview && !URLSearchParams.get('modelId')) {
        console.debug("Model Id not found")

        return <div className="sb3-flex sb3-items-center sb3-flex-col sb3-justify-center sb3-h-screen">
            <FcDataConfiguration className="sb3-text-9xl"/>
            <span className="sb3-text-xl sb3-font-bold">No model id specified</span>
        </div>
    }

    const selectedTheme = () => {
        if(theme === THEMES.APPLICO)
            return <ApplicoTheme/>
    
        if(theme === THEMES.CONFIGURATOR)
            return <TestTheme/>

        if(theme === THEMES.TEST)
            return <TestTheme/>
        
        if(theme === THEMES.CUMMINS)
            return <CumminsTheme/>

        if(theme === THEMES.LINEAR)
            return <LinearTheme/>

        return <LoadingComponent/>
    }

    const styles: any = {}
    if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_BACKGROUND]) styles.backgroundColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_BACKGROUND]

    if(isOpen === false) return <></> 

    return (
        <div
            className="sb3-fixed sb3-top-0 sb3-left-0 sb3-w-screen sb3-h-screen sb3-bg-[rgba(0, 0, 0, 0.75)] sb3-overflow-auto sb3-z-[25] sb3-contain"
            >
                <>
                    <div id="modal-root" className="sb3-z-[5000] sb3-relative"></div>

                    <div className="sb3-bg-white sb3-h-screen sb3-overflow-auto sb3-text-base"
                        id="scrollableDiv"
                    >
                        <div style = {styles} className="sb3-min-h-screen">
                            {selectedTheme()}
                            <ToastContainer />
                        </div>
                    </div>
                </>
        </div>
    )
}

export default ThemeSelector