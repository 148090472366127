import React from 'react';
import { get as _get } from 'lodash';
import Utils from 'Services/Utils';

type MyProps = {
  price?: number,
  compareAtPrice?: number,
  // currency: number|string,
  middleString?: string,
  endingString?: number,
}

export const ProductPriceString = ({
  price,
  compareAtPrice,
  // currency,
  middleString,
  endingString,
}: MyProps) => {
  if(!price) return <></>

  const priceTag = (
    <span className="xrs-product__price sb3-block sb3-font-sm">
      {compareAtPrice && price < compareAtPrice && compareAtPrice != 0 ? (
        <>
          <del>
            {
              Utils.formatPrice(compareAtPrice)
            }
            {/* <FormattedNumber
              value={compareAtPrice ?? undefined}
              style="currency"
              currency={"" + currency}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            /> */}
          </del>
          {middleString && (
            <span style={{ marginRight: '3px' }}>
              {middleString}
              {`  `}
            </span>
          )}
        </>
      ) : null}
      {
        Utils.formatPrice(price)
      }
      {endingString && (
        <span style={{ color: '#3f7e23', marginLeft: '5px' }}>
          Save&nbsp;
          {
            Utils.formatPrice(endingString)
          }
        </span>
      )}
    </span>
  );

  return priceTag;
};
