import { useAppContext } from "contexts/AppContext"
import { toast } from "react-toastify"

const LinearPreview = () => {
    const {
        runtimeSpec
    } = useAppContext()

    const instance = runtimeSpec && Object.values(runtimeSpec).find((instance) => instance.class_type === "be25f0fc402f11efb6a70eae795c602d")
    const rightInstance = runtimeSpec && Object.values(runtimeSpec).find((instance) => instance.class_type === "861c005e410911ef8e30ae644f5d7b70")

    const availableLeft = +((instance?.dynamic_fields?.["82064ba041d611efab57ae644f5d7b70"]?.value as string[])?.[0] ?? 0)
    const availableRight = +((rightInstance?.dynamic_fields?.["e633cd4041d711ef931dae644f5d7b70"]?.value as string[])?.[0] ?? 0)

    return (
        <div>
            <h1 className='!sb3-text-lg sb3-font-bold'>Panel Board Preview</h1>
            <div className="sb3-grid sb3-grid-cols-2 sb3-space-x-2 sb3-bg-white sb3-p-2 sb3-border">
                <div className="sb3-space-y-2">
                    <p className="sb3-text-base">Left Side</p>

                    <div className="sb3-space-x-2">
                        <span className="sb3-bg-primary sb3-w-min sb3-text-white sb3-px-2.5 sb3-py-1 sb3-text-sm sb3-rounded">{(instance?.dynamic_fields?.["a3fd26e841cc11efba63ae644f5d7b70"]?.value as string[])?.[0] ?? 0}</span>
                        <span className="sb3-text-gray-dark">Current</span>
                    </div>
                    <div className="sb3-space-x-2">
                        <span className={`${availableLeft < 0 ? 'sb3-bg-danger' : 'sb3-bg-lime-600'} sb3-w-min sb3-text-white sb3-px-2.5 sb3-py-1 sb3-text-sm sb3-rounded`}>{availableLeft}</span>
                        <span className="sb3-text-gray-dark">Available</span>
                    </div>
                    <div className="sb3-space-x-2">
                        <span className="sb3-bg-gray-dark sb3-w-min sb3-text-white sb3-px-2.5 sb3-py-1 sb3-text-sm sb3-rounded">{
                            availableLeft
                            + +((instance?.dynamic_fields?.["a3fd26e841cc11efba63ae644f5d7b70"]?.value as string[])?.[0] ?? 0)
                        }</span>
                        <span className="sb3-text-gray-dark">Total</span>
                    </div>
                </div>
                <div className="sb3-space-y-2 ">
                    <p className="sb3-text-base">Right Side</p>

                    <div className="sb3-space-x-2">
                        <span className="sb3-bg-primary sb3-w-min sb3-text-white sb3-px-2.5 sb3-py-1 sb3-text-sm sb3-rounded">{(rightInstance?.dynamic_fields?.["805265ee41ce11ef8529ae644f5d7b70"]?.value as string[])?.[0] ?? 0}</span>
                        <span className="sb3-text-gray-dark">Current</span>
                    </div>
                    <div className="sb3-space-x-2">
                        <span className={`${availableRight < 0 ? 'sb3-bg-danger' : 'sb3-bg-lime-600'} sb3-w-min sb3-text-white sb3-px-2.5 sb3-py-1 sb3-text-sm sb3-rounded`}>{availableRight}</span>
                        <span className="sb3-text-gray-dark">Available</span>
                    </div>
                    <div className="sb3-space-x-2">
                        <span className="sb3-bg-gray-dark sb3-w-min sb3-text-white sb3-px-2.5 sb3-py-1 sb3-text-sm sb3-rounded">{
                            availableRight
                            + +((rightInstance?.dynamic_fields?.["805265ee41ce11ef8529ae644f5d7b70"]?.value as string[])?.[0] ?? 0)
                        }</span>
                        <span className="sb3-text-gray-dark">Total</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinearPreview