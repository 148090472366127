import React from 'react';


const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
	const defaultRef = React.useRef<HTMLInputElement>();
	const resolvedRef = ref as React.MutableRefObject<HTMLInputElement> || defaultRef;

	React.useEffect(() => {
		(resolvedRef as any).current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<input type="checkbox" ref={resolvedRef} {...rest} />
		</>
	);
});


export default IndeterminateCheckbox