import { MouseEventHandler, useEffect, useRef, useState } from "react";
import Header from "../Header";
// Dynamically import the components
// @ts-ignore
import {EditableMathField} from "react-mathquill"

import FillButton from "../Button/FillButton";
import { toast } from "react-toastify";
import { CHOICE_IS_DISABLED, CHOICE_NAME, THEMES } from "constants/constants";
import Utils from "Services/Utils";
import { useAppContext } from "contexts/AppContext";
import { TChoice } from "types";
import { TFractionFieldProps } from "types/components/Fields";
import Title from "components/Header/Title";

const FractionalField = (props: TFractionFieldProps) => {
    const [latex, setLatex] = useState<string>(``);
    const [error, setError] = useState<string|undefined>(undefined);
    const { activeField, theme } = useAppContext()
    const mathFieldRef = useRef<any>(null);

    useEffect(() => {
        setLatex(Utils.convertToLatex(`${props.value || props.defaultValue || ""}`))
    }, [props.value, props.defaultValue, activeField])

    useEffect(() => {
        let error = undefined
        try {
            if(latex === "") return setError("")
            const cleanedLatex = latex.replace('\\ ', ' ').replace('-', ' ').replace(/\\frac{(\d+)}{(\d+)}/g, '$1/$2 ').trim();
            const result = Utils.calculateMixedNumber(`${cleanedLatex}`)
            if(!result.valid) error = result.error
            const regexp = /![\(|\[](.*)\" to (.*)\"[\)|\]]/g;

            // check if value is present in any of the choice provided
            const check = result.value && props.items?.some((item: TChoice) => {
                const exp = item[CHOICE_NAME].replace(regexp, '$1-$2');
                const split = exp.split('-')
                const _min = Utils.calculateMixedNumber(split[0].replace('"', ""))
                const _max = split[1] ? Utils.calculateMixedNumber(split[1]) : _min
                return result.value >= (_min.value ?? 0) && result.value <= (_max.value ?? 0)
            })

            if(props.min && result.value) {
                const min = Utils.calculateMixedNumber(`${props.min}`)
                if(!min.valid)
                    error = min.error
                else if(min.value && result.value < min.value)
                    error = `Fraction should be greater than ${props.min} or ${min.value}`
            }
            if(!error && props.max && result.value) {
                const max = Utils.calculateMixedNumber(`${props.max}`)
                if(!max.valid)
                    error = max.error
                else if(max.value && result.value > max.value)
                    error = `Fraction should be smaller than ${props.max} or ${max.value}`
            }
            if(!error && props.increment && result.value) {
                const increment = Utils.calculateMixedNumber(`${props.increment}`)
                if(!increment.valid)
                    error = increment.error
                else if(increment.value && result.value % increment.value != 0)
                    error = `Fraction should be divisibe than ${props.increment} or ${increment.value}`
            }
            if(!error && !check)
                error = (!props.items || props.items.length === 0) ? 
                    "Choose another option before entering fraction field" : 
                    "Invalid value (Value may not be in configurable range)"

            if(!error) props.selectFraction(cleanedLatex)
            
            setError(error)
        } catch (error) {
            console.error('Error evaluating expression:', error);
            setError("Invalid fraction")
        }
    }, [latex]);

    const handleSelect: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        if(error) return toast.error(error)
        const normal = latex.replace('\\ ', ' ').replace('-', ' ').replace(/\\frac{(\d+)}{(\d+)}/g, '$1/$2 ').trim()
        props.selectFraction(normal)
    }

    const handleInputChange = (mathField: any) => {
        setLatex(mathField.latex());
    };


    const cleanedLatex = latex.replace('\\ ', ' ').replace('-', ' ').replace(/\\frac{(\d+)}{(\d+)}/g, '$1/$2 ').trim();
    const result = Utils.calculateMixedNumber(`${cleanedLatex}`)

    const allDisabled = props.items?.length === 0 || props.items?.every((choice: TChoice) => choice[CHOICE_IS_DISABLED])

    return (
        <>
            <div>
                <Title title = {props.title + " (Fraction or decimal)" } required={props.required} description={props.description} info={props.info}/>
            </div>
            <div className="sb3-mb-3" ref={mathFieldRef}>
                <EditableMathField
                    className="sb3-w-72 sb3-h-16"
                    style={{ 
                        borderRadius: '0.25rem', 
                        backgroundColor: allDisabled ? "#e5e7eb" : '#fff', 
                        border: '1px solid #E4E4E8', 
                        marginTop: '0.25rem', 
                        paddingLeft: '0.625rem', 
                        paddingRight: '0.625rem', 
                        gap: '2px', 
                        color: '#090a42', 
                        outline: 'none', 
                        boxShadow: 'none' 
                    }}
                    latex={latex}
                    title='Placeholder'
                    onChange={(mathField: any) => !allDisabled && handleInputChange(mathField)}
                    placeholder='eg. 1-1/2'
                    config={{
                        spaceBehavesLikeTab: false, 
                        leftRightIntoCmdGoes: 'up', 
                        restrictMismatchedBrackets: true, 
                        substituteTextarea: () => {
                            const textarea = document.createElement('textarea')
                            textarea.disabled = allDisabled
                            return textarea
                        },
                    }}
                />
                <p className="sb3-text-gray-500 sb3-text-xs">
                    eg. 1 1/2
                </p>

                <p className="sb3-text-xs">
                    <span className="sb3-font-bold">Range</span> min {props.min} - max {props.max}
                </p>
                
               <div className="sb3-mt-3 sb3-space-y-1">
                    <label>Decimal value</label>
                    <input type="number" disabled={true} value={result.value ?? 0} className="sb3-bg-gray-200 sb3-w-72 sb3-rounded sb3-block sb3-p-2"/>
               </div>

                {error && <span className="sb3-text-red-500 sb3-text-xs">{error}</span>}
                { theme === THEMES.APPLICO &&  <FillButton type="button" className="mt-2" disabled={allDisabled || props.dataLoading || !!error} onClick = {handleSelect}>Submit</FillButton>  }
            </div>
        </>
    )
}

export default FractionalField