import React from "react";

/**
 * Loading Element
 * 
 * Types:
 * - text
 * - text50
 * - text25
 * - title
 * - title25
 * - avatar
 * - thumbnail
 * - thumbnail300
 * 
 * @param param0 
 * @returns 
 */

const SkeletonElement = ({ type }: {type: string}) => {
  const classes = `skeleton ${type}`;
  return <div className={classes}></div>;
};

export default SkeletonElement;
