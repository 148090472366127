import { CATEGORY_ID, CONF_REQUIRED, CONNECTOR_ID, FIELD_DATA_CONFIGURATION, FIELD_HIDDEN, FIELD_ID, FIELD_NAME, FIELD_TYPES, FRMDATA_FIELD_TYPE, FRMDATA_GROUP_LABEL, FRMDATA_ITEMS, FRMDATA_TYPE, MODEL_CATEGORIES, MODEL_CONNECTORS, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, MODEL_SUBMODEL_ID, PAGE_LAYOUT, PAGE_LAYOUTS, RUNTIME_ELEMENT_TYPES, RUNTIME_INSTANCE_ELEMENT_TYPE, RUNTIME_INSTANCE_FIELD_REQUIRED, RUNTIME_INSTANCE_FIELD_VALUE, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_IS_HIDDEN, RUNTIME_INSTANCE_IS_INPUT, RUNTIME_INSTANCE_REQUIRED } from "constants/constants"
import { TModel, TProductCategory, TRuntimeInstance, TUI_BuilderSubmodel, TUI_Model, TUI_Node } from "types"
import Field from "./Field"
import Summary from "components/Summary/Summary"
import { isArray } from "lodash"
import Categories from "./Category/Categories"
import { useAppContext } from "contexts/AppContext"
import { Fragment } from "react"
import NodeHelper from "helpers/NodeHelper"
import { isField } from "@apollo/client/utilities"
import { TCartItem } from "types/Cart"
import Utils from "Services/Utils"
import Title from "components/Header/Title"

type MyProps = {
    model: TModel,
    instance: TRuntimeInstance,
    visibleFields: string[]
    visibleConnectors: string[]
    visibleCategories: string[]
    uiModel: TUI_BuilderSubmodel
}

const Submodel = ({model, instance, visibleFields, visibleConnectors, visibleCategories, uiModel }: MyProps) => {
    const {
        activePage,
        theme,
        specs,
        cart
    } = useAppContext()

    const summaryData = Utils.generateSummaryData(
        model?.[MODEL_DATA][MODEL_FIELDS], 
        instance,
        visibleFields,
    )

    const isFieldsDone = NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(instance, activePage?.[PAGE_LAYOUT], true)

    const fieldGroups = uiModel[FRMDATA_ITEMS]
        .filter((fieldB) => {
            const fieldR = instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

            if(!fieldR) return false

            if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD) 
                return (fieldR[RUNTIME_INSTANCE_IS_HIDDEN] === false) &&
                    fieldR[RUNTIME_INSTANCE_IS_INPUT]

            return fieldR[RUNTIME_INSTANCE_IS_HIDDEN] === false
        })
        .reduce((acc: (TUI_Node|TUI_Node[])[], node) => {
            if(!node[FRMDATA_GROUP_LABEL] || node[FRMDATA_TYPE] === FIELD_TYPES.CATEGORY) {
                acc.push(node)
                return acc
            }

            if(
                acc.length === 0 
                || !isArray(acc[acc.length - 1]) 
                ||(acc as TUI_Node[][])[acc.length - 1][0][FRMDATA_GROUP_LABEL] !== node[FRMDATA_GROUP_LABEL]
            ) {
                acc.push([node])
                return acc
            }

            (acc as TUI_Node[][])[acc.length - 1].push(node)
            return acc
        }, [])

    return (
    <>
        {
            fieldGroups
            .map((fieldB, key) => {
                if(isArray(fieldB)){
                    return <div key={key} className="sb3-space-y-1 sb3-relative">
                        <Title title={fieldB[0][FRMDATA_GROUP_LABEL]} titleClassName="!sb3-text-gray-extraDark !sb3-font-normal "/>
                        <div className="sb3-flex sb3-flex-wrap sb3-gap-x-5 sb3-gap-y-2">
                        {
                            fieldB.map((node, key1) => {
                                const fieldR = instance[MODEL_DYNAMIC_FIELDS][node[FIELD_ID]]

                                if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD)
                                    return <Field 
                                        required={fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED]}
                                        instance={instance} 
                                        fieldB = {node} 
                                        key={key1}
                                        submodel={model}
                                        uiModel={uiModel}
                                        showType={node[FRMDATA_FIELD_TYPE]}
                                    />

                                return <Fragment key = {key}/>

                            })
                        }
                        </div>
                    </div>
                }

                const fieldR = instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

                if(fieldB[FRMDATA_TYPE] === FIELD_TYPES.CATEGORY) {
                    let selectedProducts: TCartItem[] = []
    
                    selectedProducts = Utils.getSelectedProductsFromCategoryIds(cart, specs[model[MODEL_SUBMODEL_ID]][MODEL_DATA][MODEL_CATEGORIES].map((cat: TProductCategory) => cat[CATEGORY_ID]), instance[RUNTIME_INSTANCE_INSTANCE_ID])

                    return (
                        <Categories 
                            key={key} 
                            submodel = {model} 
                            categories = {model?.[MODEL_DATA][MODEL_CATEGORIES]}
                            selectedProducts = {selectedProducts}
                            instance={instance}
                            uiModel={uiModel}
                            load={
                                !Utils.isLinear(theme) 
                                ||isFieldsDone
                            }
                        />
                    )
                }

                // if(fieldB[FRMDATA_TYPE] === FIELD_TYPES.CONNECTOR) return (
                //     <Connector 
                //         instance={instance} 
                //         connector = {connector} 
                //         key={key}
                //     />
                // )
                
                if(fieldR[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.FIELD) return <Field 
                    required={fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED]}
                    instance={instance} 
                    fieldB = {fieldB} 
                    key={key}
                    submodel={model}
                    uiModel={uiModel}
                    showType={fieldB[FRMDATA_FIELD_TYPE]}
                />
                
                return <Fragment key = {key}/>
            })
        }

        {
            summaryData.length > 0 && <Summary title="Project Requirements"
                items={summaryData}/>
        }
    </>
    )
}

export default Submodel