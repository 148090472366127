
import * as consts from "constants/constants"
import AppSettings from 'Settings/AppSettings'
import ServiceWrapper from 'Services/ServiceWrapper'
import AuthManager from 'Services/AuthManager'
import { TModelSpecResponse } from "types"

const api_url = AppSettings.ISR_BUILDER_API_URL

export const getNewSessionIdPreview: (modelId: string|null) => Promise<TModelSpecResponse> = (
    modelId, 
    ) => {
    return new Promise(async (resolve, reject) => {
        // generate params string for url    
        const url = `${api_url}${consts.API_PREVIEW_LOAD}`
    
        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPost(
            url,
            {project_id: modelId},
            null,
            null
        )

         
        if(result.data?.[consts.ERROR]) 
            return reject(new Error(result.data?.[consts.ERROR] ?? "Unexpected error"))

        if(result.data?.[consts.SESSION_ID]) 
            AuthManager.setSessionId(result.data[consts.SESSION_ID] ?? '')

        if(!result.data?.[consts.MODEL_SPEC] || !result.data?.[consts.RUNTIME_SPEC]) 
            return reject(new Error("Unexpected Error"))
        
        resolve(result.data) 
    })
}