import TooltipInfo from "components/TooltipInfo";
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, PAGE_LAYOUT, PAGE_LAYOUTS, THEME_SUBTITLES } from "constants/constants";
import { useAppContext } from "contexts/AppContext";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { Tooltip } from "react-tooltip";
import Utils from "Services/Utils";

type MyProps = {
    title: string,
    required?: boolean
    description?: string,
    titleClassName?: string
    info?: string
}

const Title = ({
    title, 
    required, 
    description,
    titleClassName,
    info
}: MyProps) => {
    const {
        theme,
        activePage,
        builderSpec
    } = useAppContext()
    
    if (Utils.isConfigurator(theme) && activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING) return <></>

    const styles: any = {}

    if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SUBTITLES])
        styles.color = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SUBTITLES]

    return (
        <span className="">
            <div className={`${activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && "sb3-font-semibold"} sb3-text-black sb3-text-sm sb3-font-normal sb3-flex sb3-items-center sb3-space-x-1 ${titleClassName}`} style={styles}>{title}<span className="sb3-text-red-500 !sb3-text-lg">{required && '*'}</span>{info && <TooltipInfo message={info}/>}</div>

            <p className="sb3-text-gray-extraDark sb3-text-xs">{description}</p>
        </span>
    )
};

export default Title