import Utils from "Services/Utils";
import * as consts from "constants/constants";
import _, { isArray } from "lodash";
import { TActivePage, TActiveSubmodel, TNextActiveField, TSelectedValuesNotNull, TSpec } from "types/contexts/AppContext";
import { TChoice, TConnector, TField, TModel, TModelData, TModelField, TModelSpec, TProductCategoryField, TRuntimeInstance, TUI_BuilderPage, TUI_BuilderSubmodel, TUI_Model } from "types"
import { TCart } from "types/Cart";
import { MutableRefObject } from "react";
import NodeHelper from "helpers/NodeHelper";


const useAppContextHelperHook = () => {
    const removeDuplicateFields = (fields: TField[]|TModelField[], _uniqueFieldNames: Set<string>, dataCallback: (field: any) => TField) => {
        let _fieldName: string;

        return fields?.filter((_field, fieldKey) => {
            const field = dataCallback(_field)
            _fieldName = field[consts.FIELD_NAME].replace("_", " ").replace('/', " ")
            if(_uniqueFieldNames.has(_fieldName)) return false
  
            _uniqueFieldNames.add(_fieldName)
            return true
        })
    }

    /**
     * 
     * Get the next active field for focus
     * 
     * @param {object} returnValue - this value will be updated by reference for output
     * @param {TConnector[]|undefined} connectors - connector list
     * @param {(TField|TModelField)[]} fields - fields ouside of sub model or inside
     * @param {TProductCategoryField[]} categories - categories fields
     * @param {object} _fieldIDNameMap  - by reference
     * @param {} activeField - active field to find one adscent to this if it exists
     * @param activeInstance - similar usecase as activeField
     * @param currentSelectedFields 
     * @param nextActive 
     * @param uiModel 
     * @param dataCallback 
     * @param loopCallback 
     * @param isInstantiable 
     * @param instances 
     * @returns {void}
     * 
     * 
     * {
     *     selectedValues
     *     systemSelectedFields
     * }
     */
    const getNextActiveField =  <T extends TField|TModelField>(
            returnValue: { // value that will be updated using ref
                selectedValues?: TSelectedValuesNotNull
                systemSelectedFields?: string[]
            },
            cart: TCart,
            connectors: TConnector[]|undefined,
            fields: T[], 
            categories: TProductCategoryField[]|undefined,
            _fieldIDNameMap: {[x: string]: string},
            activeField: TField,
            activeInstance: TRuntimeInstance | undefined,
            activeSubmodel: TActiveSubmodel,
            activePage: TActivePage,
            currentSelectedFields: string[], 
            nextActive: TNextActiveField,
            uiModel: TUI_BuilderSubmodel,
            cartProductIds: {categoryId: string, instanceId: string, categoryName: string, productIds: (string|number)[]}[],
            page: TUI_BuilderPage,
            dataCallback: (field: T) => TField,
            loopCallback?: (field: TField) => void,
            instances?: TRuntimeInstance[],
            submodel?: TModel,
            theme?: string|null,
            isAllRequiredSelected?: MutableRefObject<boolean>,
        ) => {
        /**
         * Applico theme 
         */
        

        // let stop = false
        // generate selected value array to update state of new values
        // let selectedValues: TSelectedValuesNotNull = {}
        // const systemSelectedFields: string[] = [] // identify values selected by system
        if(!returnValue) return
        if(!("selectedValues" in returnValue)) returnValue.selectedValues = {}
        if(!("systemSelectedFields" in returnValue)) returnValue.systemSelectedFields = []

        instances?.forEach((_instance) => {
            const nodeVisibility = NodeHelper.filterVisibleNodesUsingRuntime(_instance[consts.MODEL_DYNAMIC_FIELDS])

            const _visibleFields = fields.filter(field => {
                const _field = dataCallback(field)
                return nodeVisibility.visibleFieldIds.includes(_field[consts.FIELD_ID])
            })

            const _visibleCategories = categories?.filter(_category => {
                return nodeVisibility.visibleCategoryIds.includes(_category[consts.FIELD_ID])
            })

            // const visibleConnectors = categories?.filter(_category => {
            //     return nodeVisibility.visibleFieldIds.includes(_category[consts.FIELD_ID])
            // })

            if(!activeInstance || (theme === consts.THEMES.APPLICO && activeInstance?.[consts.MODEL_SUBMODEL_ID] === _instance[consts.RUNTIME_INSTANCE_INSTANCE_ID]) || 
                (theme === consts.THEMES.CONFIGURATOR && activeSubmodel?.instance[consts.RUNTIME_INSTANCE_INSTANCE_ID] === _instance[consts.RUNTIME_INSTANCE_INSTANCE_ID])
            ) nextActive.activeInstance = 0
            
            _visibleCategories?.forEach((category) => {
                const instanceCategory = _instance[consts.MODEL_DYNAMIC_FIELDS][category[consts.CATEGORY_ID]]
               
                if(
                    !instanceCategory 
                    || !isArray(instanceCategory[consts.RUNTIME_INSTANCE_FIELD_VALUE]) 
                    || (!instanceCategory[consts.RUNTIME_INSTANCE_FIELD_VALUE] || instanceCategory[consts.RUNTIME_INSTANCE_FIELD_VALUE].length === 0)
                ) return;
                
                cartProductIds.push({
                    categoryId: category[consts.CATEGORY_ID], 
                    categoryName: category[consts.MODEL_DATA][consts.CATEGORY_NAME], 
                    productIds: instanceCategory[consts.RUNTIME_INSTANCE_FIELD_VALUE],
                    instanceId: _instance[consts.RUNTIME_INSTANCE_INSTANCE_ID]
                })
            })

            if(theme === consts.THEMES.CONFIGURATOR){
                if(nextActive.active === 1) return
                if(!activeSubmodel?.[consts.MODEL_SUBMODEL_ID] || submodel?.[consts.MODEL_SUBMODEL_ID] === activeSubmodel[consts.MODEL_SUBMODEL_ID])
                    nextActive.active = 0

                if((!nextActive.submodel || nextActive.activeInstance === 0) 
                    && Object.values(_instance.dynamic_fields).filter(field => field.type === "Category").some((category) => {
                        return isArray(category[consts.RUNTIME_INSTANCE_FIELD_VALUE]) &&  category[consts.RUNTIME_INSTANCE_FIELD_VALUE].length < (category[consts.RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1)
                    })){
                       
                    if(nextActive.active === 0 || !nextActive.submodel){
                        nextActive.submodel = submodel
                        nextActive.instance = _instance
                        nextActive.uiModel = uiModel
                    }

                    // set active=1 if `
                    if(nextActive.active === 0) {
                        nextActive.active = 1
                        nextActive.activeInstance = 1
                    }
                }
            }


            _visibleFields.forEach((_field, fieldKey) => {
                const field = dataCallback(_field)

                if(!_fieldIDNameMap[field[consts.FIELD_ID]])
                    _fieldIDNameMap[NodeHelper.getFieldKeyForSelectedValues(field, _instance)] = field[consts.FIELD_NAME] 
                
                const selectedValue = NodeHelper.getFieldValue(_instance, field)

                const _allDisabled = _instance[consts.MODEL_DYNAMIC_FIELDS][field[consts.FIELD_ID]][consts.RUNTIME_INSTANCE_CHOICES]?.every((choice) => choice[consts.CHOICE_IS_DISABLED])
                if(selectedValue && !currentSelectedFields.includes(field[consts.FIELD_ID])) returnValue.systemSelectedFields?.push(field[consts.FIELD_ID])
                
                // generate array of selected inputs 
                if(selectedValue && returnValue.selectedValues) returnValue.selectedValues[NodeHelper.getFieldKeyForSelectedValues(field, _instance)] = selectedValue
                
                // addition function for fields from initiater
                if(loopCallback) loopCallback(field)

                // if next field is selected then no need to run 
                // next stmts
                if(theme === consts.THEMES.APPLICO){
                    if(
                        nextActive.active === 1 
                        || (!_instance[consts.MODEL_DYNAMIC_FIELDS][field[consts.FIELD_ID]][consts.RUNTIME_INSTANCE_IS_INPUT])
                    ) return
                    
                    // check if this field is the current active field
                    // set active to 0 if this is the same
                    if(!activeField || (field[consts.FIELD_ID] === activeField[consts.FIELD_ID] && nextActive.activeInstance === 0)) 
                        nextActive.active = 0
                    
                    if(!_allDisabled && !selectedValue && (nextActive.activeInstance === 0 || !nextActive.field)) {
                        if(
                            nextActive.active === 0 || // if this field is adjecent to the current active field then override first field
                            !nextActive.field // if next field is not set -> then set the first one as next)
                        ){
                            nextActive.field = field
                            nextActive.type = "field"
                            nextActive.submodel = submodel
                            nextActive.instance = _instance
                            nextActive.uiModel = uiModel
                        }
    
                        // set active=1 if 
                        if(nextActive.active === 0) {
                            nextActive.active = 1
                            nextActive.activeInstance = 1
                        }
                    } 
                }
            })
        })
    }

    const generateSpecsFromModelSpec = (modelSpec: TModelSpec) => {
        const _modelSpec: TModelSpec = JSON.parse(JSON.stringify(modelSpec))
        const _specs: TSpec = {}

        _modelSpec?.[consts.DEFN_PROJECT]?.[consts.DATA_MODELS]?.forEach((model, modelKey) => {
            _specs[model[consts.MODEL_SUBMODEL_ID]] = model
        })
        _modelSpec?.[consts.DEFN_PROJECT]?.[consts.MODEL_CATEGORIES].forEach((category) => {
            _specs[category[consts.CATEGORY_ID]] = category
        })
        _modelSpec?.[consts.DEFN_PROJECT]?.[consts.MODEL_FIELDS].forEach((field) => {
            _specs[field[consts.MODEL_DATA][consts.FIELD_ID]] = field
        })

        return _specs
    }

    const getImagesFromChoices = (choices: TChoice[], acc: string[]) => {
        return choices.reduce((acc: string[], choice) => {
            if(choice[consts.CHOICE_IMAGE]){
                const pdImages = JSON.parse(choice[consts.CHOICE_IMAGE])
                if(pdImages?.[0]?.JPEG_IMAGES?.default_image) acc.push(pdImages[0].JPEG_IMAGES.default_image)
                if(isArray(pdImages?.[0]?.JPEG_IMAGES?.images)) acc.push(...pdImages[0].JPEG_IMAGES.images)
            }

            return acc
        }, [])
    }

    return {
        getNextActiveField,
        removeDuplicateFields,
        generateSpecsFromModelSpec,
        getImagesFromChoices
    }
}

export default useAppContextHelperHook
