import { PropsWithChildren } from "react"
import { TAccordionBodyProps } from "types/components/Accordion"
// import { m } from "framer-motion"

/**
 * Accordion body element 
 * use with combination of AccordionLayout
 * 
 * @param children
 * @returns 
 */
const AccordionBody = ({isOpen, children, className, showOnClose}: PropsWithChildren<TAccordionBodyProps>) => {
    if(!isOpen && !showOnClose) return <></>

    return <div 
        // initial = {{ opacity:0, maxHeight: 0, animation: "easeInOut"}} 
        // animate = {{ opacity: 1, maxHeight: "200vh", animation: "easeInOut" }}
        // exit = {{ opacity: 0, maxHeight: 0, animation: "easeInOut"}}
        className={`sb3-w-full sb3-space-y-3 ${className}`}>
            {children}
        </div>
}

export default AccordionBody