import Header from "../Header";
import SummaryItem from "../Header/SummaryItem";

type MyProps = {
    title?: string,
    items: Array<{ title: string, description: string| number | null | undefined }>
}

/**
 * A grayed area to show non editable 
 * summary values
 * 
 * @param {string} title
 * @param {array} items - [{title: 'Frequency', description: '60Hz'}, {title: 'Frequency', description: '60Hz'}]
 * @returns 
 * 
 * <Summary title="Project Requirements"
 *  items={[{title: 'Frequency', description: '60Hz'}, {title: 'Frequency', description: '60Hz'}]}/>
 * 
 */
const Summary = ({title, items}: MyProps) => (
    <div className="sb3-rounded sb3-bg-gray-100 sb3-p-2 sb3-space-y-2 sb3-mt-3">
        {title && <Header title={title}/>}
        <div className="sb3-grid sb3-grid-cols-[repeat(auto-fill,_minmax(100px,_max-content))] sb3-max-w-full sb3-gap-y-2 sb3-gap-x-3">
            {
                items.map((e, key) => <SummaryItem key={key} title={e.title} description={e.description ?? ""}/>)
            }
        </div>
    </div>
);

export default Summary
