import Title from "components/Header/Title"
import { TToggleButtonProps } from "types/components/ToggleButton"

const ToggleField = ({
    value = false,
    name = "toggle-button",
    defaultValue,
    disabled,
    onChange,
    required,
    description,
    info
}: TToggleButtonProps) => {
    return (
        <div className="sb3-space-y-1">
            <Title title={name} required={required} description={description} info={info}/>
            <label htmlFor = {name} className="sb3-relative sb3-inline-flex sb3-items-center sb3-cursor-pointer">
                <input 
                type="checkbox" 
                checked={value[0]} 
                disabled = {disabled}
                name={name} 
                id={name} 
                onChange={onChange}
                className={`sb3-sr-only peer/toggle-button-checkbox`}/>
                <div className={`sb3-border-gray sb3-border sb3-w-11 sb3-h-6 sb3-bg-lightgray peer-focus/toggle-button-checkbox:sb3-outline-none 
                    sb3-rounded-full sb3-transition-all sb3-duration-300 after:sb3-duration-300
                    peer-disabled/toggle-button-checkbox:sb3-bg-lightgray
                    peer-disabled/toggle-button-checkbox:sb3-border-lightgray
                    dark:sb3-bg-lightgray peer-checked/toggle-button-checkbox:after:sb3-translate-x-full peer-checked/toggle-button-checkbox:after:sb3-border-white 
                    peer-checked/toggle-button-checkbox:after:bg-white 
                    after:sb3-content-[''] after:sb3-absolute after:sb3-top-[2px] after:sb3-left-[2px] after:sb3-shadow-md after:sb3-shadow-gray-600
                    after:sb3-border-white after:sb3-bg-white after:sb3-border after:sb3-rounded-full after:sb3-h-5 after:sb3-w-5 
                    after:sb3-transition-all dark:sb3-border-lightgray peer-checked/toggle-button-checkbox:sb3-border-[#488328] peer-checked/toggle-button-checkbox:sb3-bg-[#488328]`}></div>
            </label>
        </div>
    )
}

export default ToggleField