import { DragDropContext } from 'react-beautiful-dnd';
import { TDragAndDropProps } from './DragAndDrop';
import StrictModeDroppable from './StrictModeDroppable';

/**
 * Common catainer for drag and drop
 * 
 * @param {number|string} id - unique identifier
 * @param {string} className - optional class name will be used instead of all existing class names
 * @param {DraggableChildrenFn} renderItem - item to render
 * @param onDragEnd
 *  
 * points to remember
 * - if the parent has any positioning other than relative then use this to pass the draggable
 *  other wise dragging may be cause unwanted issues
 */

export default function DragAndDropContainer({
    id,
    className=`sb3-max-w-full sb3-border sb3-rounded-sm sb3-h-[319px] sb3-p-2 sb3-space-y-2 sb3-overflow-auto relative sb3-transform-none`,
    children,
    renderItem,
    onDragEnd
}: TDragAndDropProps){
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="columnArrange" renderClone={renderItem || undefined}>
            {
            (provided) =>
            <div ref={provided.innerRef} className={className} >
                {children}
                {provided.placeholder}
            </div> 
            }
            </StrictModeDroppable> 
        </DragDropContext>
    )
}