// import { useAppContext } from "contexts/AppContext";
// import {
//     CONF_REQUIRED,
//     FIELD_DATA_CONFIGURATION,
// } from "constants/constants";
// import Field from "components/Submodel/Field";
// import * as consts from "../constants/constants";
// import { useEffect } from "react";
// import NProgress from 'nprogress'
// import { TdisabledModal } from "types/contexts/AppContext";
// import DisabledModal from "components/DisabledModal";
// import StepsComponent from "components/Steps";
// import Sidebar from "components/Navbar/Sidebar";
// import ViewConfigurations from "components/Configurations";
// import MultiChoiceGrid from "components/Input/MultiChoiceGrid";
// import Navbar from "components/Navbar";
// import { TField, TModel } from "types";
// import Visualizer from "components/Visualizer";
// import { FaBars } from "react-icons/fa";

const ApplicoTheme = () => {
    return <></>
//     const {
//         filteredModelSpec,
//         uiDefn,
//         disabledModal,
//         setDisabledModal,
//         viewConfigurations,
//         activeField,
//         loading,
//         sidebarToggle,
//         setSidebarToggle,
//         activeSubmodel,
//         updateInstanceInput
//     } = useAppContext()

//     useEffect(() => {
//         if(!filteredModelSpec && !uiDefn) {
//             NProgress.start()
//         }
//         else{
//             NProgress.done()
//         }
//         return () => {
//             NProgress.done()
//         }
//     }, [filteredModelSpec, uiDefn])

//     // useEffect(() => {
//     //     function handleResize() {
//     //         setRightPanelWidth(rightPanelRef?.current?.clientWidth ?? "auto");
//     //     }

//     //     rightPanelRef?.current && handleResize()
//     //     window.addEventListener('resize', handleResize);
//     //     return () => window.removeEventListener('resize', handleResize);
//     // }, [])

//     return (
//         <>
//         <Navbar/>
//         <div className="max-w-7xl m-auto py-3">
//             <StepsComponent/>
//             <main className="min-h-content text-black max-w-7xl m-auto py-3 px-3 xl:px-0">
//             <div className="flex">
//                 <div className="z-[2000] rounded-lg">
//                 <div className="max-h-content overflow-auto hidden lg:block shadow  bp-0 min-h-content">
//                         <Sidebar
//                             submodels = {filteredModelSpec?.[consts.DEFN_PROJECT]?.[consts.DATA_MODELS]}
//                             fields = {filteredModelSpec?.[consts.DEFN_PROJECT]?.[consts.MODEL_FIELDS]}
//                             categories = {filteredModelSpec?.[consts.DEFN_PROJECT]?.[consts.MODEL_CATEGORIES]}
//                             loading={loading}
//                         />
//                     </div>

//                     {
//                         sidebarToggle && <div className="fixed top-0 left-0 h-full border max-w-screen z-10 max-h-screen block lg:hidden ">
//                             <div className="max-h-full overflow-auto ">
//                                 <Sidebar
//                                     submodels = {filteredModelSpec?.[consts.DEFN_PROJECT]?.[consts.DATA_MODELS]}
//                                     fields = {filteredModelSpec?.[consts.DEFN_PROJECT]?.[consts.MODEL_FIELDS]}
//                                     categories = {filteredModelSpec?.[consts.DEFN_PROJECT]?.[consts.MODEL_CATEGORIES]}
//                                     loading={loading}
//                                     />
//                             </div>
//                             <button 
//                             onClick={() => setSidebarToggle(false)}
//                             className="absolute right-0 top-0 translate-x-full bg-white py-5 px-3 rounded-r z-10 border border-l-0">
//                                 <FaBars/>
//                             </button>
//                         </div>
//                     }
//                     </div>

//                     <div className="flex w-full">
//                     {/* fields */}
//                         <div className="w-7/12 relative max-h-content min-h-content max-w-full px-3 ">

//                             <div className='bg-white  rounded-lg h-full overflow-y-auto overflow-x-hidden px-3 shadow'>
//                             {/* <Tabs
//                                 viewConfigurations={viewConfigurations}
//                                 setViewConfigurations={setViewConfigurations}
//                                 handleReset={handleReset}
//                             /> */}
                            
//                                 <div className="pt-2">
//                                     {
//                                     (!loading && !viewConfigurations) && activeField &&
//                                         <Field
//                                             required={(activeField.field as TField)?.[FIELD_DATA_CONFIGURATION][CONF_REQUIRED]}
//                                             instance={activeField.instance} 
//                                             field = {activeField.field as TField} 
//                                             submodel={(activeSubmodel as TModel)}
//                                             uiModel={activeField.uiModel}
//                                         />
//                                     }
//                                     {
//                                     (loading) &&
//                                         // skeleton
//                                         <MultiChoiceGrid 
//                                             title="Loading"
//                                             items = {[...Array(2)]}
//                                             disabled={() => true}
//                                             getName={() => ""}
//                                             // getImage = {(item) => Utils.getChoiceImage(item, selectedValues)}
//                                             loading={true}
//                                             isConfigure = {true}
//                                             isMulti={false}
//                                         />
//                                     }
//                                 </div>

//                                 <DisabledModal
//                                     showModal={disabledModal}
//                                     closeModal={() => setDisabledModal(null)}
//                                     onConfirm={() => updateInstanceInput((disabledModal as TdisabledModal).fieldId, (disabledModal as TdisabledModal).items, disabledModal?.instanceId, true, false, false, disabledModal?.item)}
//                                     loading={false}
//                                 />
//                             </div>
                                
//                         </div>

//                         <div className="min-h-content w-5/12 max-h-content overflow-auto flex flex-col space-y-2">
//                             {/* visualizer */}
//                             <Visualizer/>

//                             <ViewConfigurations/>
//                         </div>

//                     </div>
//                 </div>
//             </main>
//         </div>
//         </>
//     );
}

export default ApplicoTheme