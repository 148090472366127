import { useAppContext } from "contexts/AppContext"
import AccordionBody from "./Accordion/AccordionBody"
import AccordionHeader from "./Accordion/AccordionHeader"
import AccordionLayout from "./Accordion/AccordionLayout"
import Header from "./Header"
import { TModel, TRuntimeInstance, TUI_BuilderSubmodel, TUI_Node } from "types"
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, FIELD_ID, FIELD_NAME, FIELD_TYPES, FRMDATA_DISPLAY_NAME, FRMDATA_FIELD_TYPE, FRMDATA_ID, FRMDATA_ITEMS, FRMDATA_MODEL_FIELD_TYPE, FRMDATA_SMID, FRMDATA_TYPE, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, MODEL_SUBMODEL_ID, PAGE_FORM_DATA, PAGE_NAME, RUNTIME_INSTANCE_FIELD_NAME, RUNTIME_INSTANCE_FIELD_REQUIRED, RUNTIME_INSTANCE_FIELD_VALUE, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_IS_INPUT, THEME_FILL_BUTTON_BG, THEME_FILL_BUTTON_CLR } from "constants/constants"
import Field from "./Submodel/Field"
import NodeHelper from "helpers/NodeHelper"
import { TActiveSubmodel } from "types/contexts/AppContext"
import { isArray } from "lodash"
import { FaTimes } from "react-icons/fa"
import BorderlessButton from "./Button/BorderlessButton"
import Summary from "./Summary/Summary"

// @todo pass fields from parent
const Filters = () => { 
    const {
        // activeSubmodel,
        specs,
        activePage,
        getInstances,
        updateInstanceInputs,
        builderSpec
    } = useAppContext()

    const activeSubmodel: TActiveSubmodel = activePage && {
        ...specs[activePage[PAGE_FORM_DATA][0][FRMDATA_SMID]], 
        instance: getInstances(activePage[PAGE_FORM_DATA][0][FRMDATA_SMID])?.[0],
        uiModel: activePage[PAGE_FORM_DATA][0]
    }
    if(!activeSubmodel || !activePage) return <p>No fields found</p>

    const visibleFields = activeSubmodel?.instance?.[MODEL_DYNAMIC_FIELDS] && NodeHelper.filterVisibleNodesUsingRuntime(activeSubmodel.instance[MODEL_DYNAMIC_FIELDS])

    const submodelUI = activePage[PAGE_FORM_DATA]?.find((submodel) => submodel[FRMDATA_SMID] === activeSubmodel[MODEL_SUBMODEL_ID]) as TUI_BuilderSubmodel

    const filters = (activeSubmodel?.instance?.[MODEL_DYNAMIC_FIELDS] && Object.entries(activeSubmodel?.instance[MODEL_DYNAMIC_FIELDS])?.filter(([fieldId,field]) => {
        return field.type !== "Category" 
        && visibleFields?.visibleFieldIds?.includes(fieldId) 
        && field[RUNTIME_INSTANCE_IS_INPUT] 
        && isArray(field[RUNTIME_INSTANCE_FIELD_VALUE]) 
        && field[RUNTIME_INSTANCE_FIELD_VALUE].length > 0
    })) ?? []


    const handleClearAllFilter = () => {
        if(!activeSubmodel?.instance[MODEL_DYNAMIC_FIELDS]) return

        const value = Object.fromEntries(
            filters.map(([fieldId, _]) => ([fieldId, []]))
        )
        updateInstanceInputs(value, activeSubmodel.instance[RUNTIME_INSTANCE_INSTANCE_ID])
    }

    const handleRemoveFilter = (field: string, values: string[], value: string) => {
        if(!activeSubmodel?.instance) return

        const newValues = [...values]
        const index = newValues.indexOf(value)
        if(index === -1) return

        newValues.splice(index, 1)
        updateInstanceInputs({[field]: newValues}, activeSubmodel.instance[RUNTIME_INSTANCE_INSTANCE_ID])
    }

    const styles: any = {}

    if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]) {
        styles.backgroundColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
        styles.borderColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
        styles.color = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]
      }

      const summaryData = activeSubmodel?.instance && Object.entries(activeSubmodel?.instance[MODEL_DYNAMIC_FIELDS])?.filter(([fieldId,field]) => {
            return field.type !== "Category" 
            && visibleFields?.visibleFieldIds?.includes(fieldId) 
            && !field[RUNTIME_INSTANCE_IS_INPUT] 
            && isArray(field[RUNTIME_INSTANCE_FIELD_VALUE]) 
            && field[RUNTIME_INSTANCE_FIELD_VALUE].length > 0
        }).map(([fieldId,field]) => ({title: field[FIELD_NAME], description: isArray(field[RUNTIME_INSTANCE_FIELD_VALUE]) 
            ? (field[RUNTIME_INSTANCE_FIELD_VALUE] as (string|number)[])?.join(', ') 
            : (field[RUNTIME_INSTANCE_FIELD_VALUE] as string|number)}))


    return (
        <div>
        {
            summaryData?.length > 0 && <div className="sb3-pb-2">
                <Summary
                items={summaryData}/>
            </div>
        }
        {
            filters?.length > 0 && 
            <div className="sb3-flex sb3-gap-2 sb3-flex-wrap sb3-max-w-full">
                {
                    filters
                    .map(([fieldKey, field]) => {
                            return (field[RUNTIME_INSTANCE_FIELD_VALUE] as string[])?.map((value) => (
                                <span className="sb3-text-xs sb3-rounded-none sb3-border sb3-border-primary sb3-flex w-min" style={{borderColor: styles.borderColor}}>
                                        <span className="p- sb3-px-1.5 sb3-w-max sb3-flex sb3-items-center">{field[RUNTIME_INSTANCE_FIELD_NAME]}</span>
                                        <span className="sb3-bg-primary sb3-text-white p- sb3-px-1.5 sb3-flex sb3-items-center sb3-w-max" style={{backgroundColor: styles.backgroundColor}}>{(value)} 
                                        <button type="button" onClick = {() => handleRemoveFilter(fieldKey, field[RUNTIME_INSTANCE_FIELD_VALUE] as string[], value)} 
                                        className="sb3-border-0 hover:sb3-text-white sb3-text-white hover:sb3-bg-transparent focus:sb3-text-white focus:sb3-border-0 hover:sb3-border-0 focus:sb3-outline-none focus:sb3-bg-transparent"><FaTimes className="sb3-ml-2"/></button>
                                    </span>
                                </span>
                            ))
                        }
                    )
                }
                <BorderlessButton onClick={handleClearAllFilter} className="sb3-min-w-min sb3-px-0">Clear All</BorderlessButton>
            </div>
        }
        {  
            submodelUI?.[FRMDATA_ITEMS]
            // (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)[MODEL_DATA][MODEL_FIELDS]
            .filter((fieldB) => {
                const fieldR = activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

                return fieldR && visibleFields.visibleFieldIds.includes(fieldB[FIELD_ID]) && fieldR[RUNTIME_INSTANCE_IS_INPUT] 
            })
            .map((fieldB, key) => {
                const fieldR = activeSubmodel.instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]
                
                const fieldValue = NodeHelper.getFieldValueByFieldR(fieldR)


                return (
                    <div className="last:sb3-border-b-0 sb3-border-b" key={key}>
                        <AccordionLayout initialState={true} resetOn={activeSubmodel[MODEL_SUBMODEL_ID] ? [activeSubmodel[MODEL_SUBMODEL_ID]] : []}>
                        {(provided) => (
                            <>
                                <AccordionHeader {...provided.getHeaderProps()}>
                                <div className="sb3-py-1.5"> 
                                    <Header 
                                        title={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]}
                                        className="sb3-text-gray-extraDark sb3-max-w-full sb3-whitespace-break-spaces"
                                        required = {fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED] && !(fieldValue && fieldValue?.length > 0)}
                                    />
                                </div>
                                </AccordionHeader>

                                <AccordionBody {...provided.getBodyProps()}>
                                    <div className={`sb3-pb-2 ${fieldB[FRMDATA_TYPE] === FIELD_TYPES.MULTI_CHOICE && "sb3-max-h-52"} sb3-overflow-auto sb3-overflow-x-hidden`}>
                                        <Field 
                                            required={fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED]}
                                            instance={activeSubmodel.instance} 
                                            fieldB = {fieldB} 
                                            key={key}
                                            isFilter = {true}
                                            isSearchEnabled = {true}
                                            uiModel={submodelUI}
                                            showType = {fieldB[FRMDATA_FIELD_TYPE]}
                                            submodel={specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel}
                                        />
                                    </div>
                                </AccordionBody> 
                            </>
                            )}
                        </AccordionLayout>
                    </div>
                )
            }
            ) 
        }
        </div>
    )
}

export default Filters