import FillButton from "./Button/FillButton"
import ModalBody from "./Modal/ModalBody"
import ModalContainer from "./Modal/ModalContainer"
import ModalFooter from "./Modal/ModalFooter"
import BorderedButton from "components/Button/BorderedButton";
import ModalHeader from "./Modal/ModalHeader";
import { useAppContext } from "contexts/AppContext";
// import { useLocation, useNavigate } from "react-router-dom";

type MyProps = {
  isOpen: boolean,
  closeModal: () => void,
  onConfirm: () => void
}

const CloseConfirmModal = ({
  isOpen,
  closeModal,
  onConfirm
}: MyProps) => {
    const {
      setIsCloseModalOpen,
      setTheme
    } = useAppContext()
    // const location = useLocation()
    // const navigate = useNavigate();

    const handleClose = () => {
        // const params = new URLSearchParams(location.search);

        // if (params.has('smartBuilder')) {
        //     params.delete('smartBuilder');
        // }
        // if (params.has('blank')) {
        //     params.delete('blank');
        // }
        // const newUrl = `${location.pathname}?${params.toString()}`;

        // // Update the URL with the new parameters
        // // navigate(newUrl, { replace: true });
        // window.location.replace(newUrl);
        setIsCloseModalOpen(false)
        onConfirm()    
        setTheme(null)
    }

    return (
    <ModalContainer
      className="!sb3-min-h-min"
      isOpen={!!isOpen}
      setOpen={closeModal}
    >
      <ModalHeader>
          <h3>Are you sure you'd like to exit the builder?</h3>        
      </ModalHeader>
      <ModalBody>
          <p>Your configurations will be lost if you exit.</p>        
      </ModalBody>
      <ModalFooter>
          <div className="sb3-flex sb3-justify-center sb3-space-x-3">
            <FillButton 
                  className="sb3-px-5 sb3-h-full"
                  onClick={handleClose}
              >Exit</FillButton>
            <BorderedButton 
                  className="sb3-px-5 sb3-h-full"
                  onClick={closeModal}
              >No, Thanks</BorderedButton>
          </div>
      </ModalFooter>
    </ModalContainer>
    )
  }

export default CloseConfirmModal