import React, { FC, useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash';
import { FaSearch } from 'react-icons/fa';

export interface TCustomSearchBarProps {
  delay?: number,
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  handleSearchChange: (term: string) => void
  value?: string
  autoComplete?: string
  placeholder?: string
  disabled?: boolean
}

/**
 * search input with debounce
 * 
 * @param props 
 * @returns 
 */
const SearchBar = (props: TCustomSearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState<string>(props.value ?? "")
  const debouncedChange = useCallback(debounce(props.handleSearchChange, props.delay ?? 600), []);
  
  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    
    setSearchTerm(e.target.value.trimStart())
    debouncedChange(e.target.value.trimStart())
  }

  useEffect(() => {
    setSearchTerm(props.value ?? "")
  }, [props.value])

  return (
    <div className={`sb3-flex sb3-item-center sb3-h-9 sb3-rounded-[4px] sb3-w-full sb3-bg-white sb3-border`}>
        <input
          id="inputID"
          type="search"
          className={`sb3-w-30 focus:!sb3-shadow-none focus:!sb3-outline-none !sb3-border-0 sb3-text-xs sb3-block sb3-py-1 sb3-px-3 sb3-bg-transparent sb3-bg-clip-padding sb3-text-[#6B778C] focus:sb3-text-gray-700 sb3-rounded-[4px] sb3-flex-grow sb3-font-normal sb3-shadow-none  autofill:sb3-shadow-none`}
          placeholder={props.placeholder ?? "Search category"}
          aria-label="Search"
          aria-describedby="basic-addon1"
          autoComplete={props.autoComplete ?? "on"}
          value = {searchTerm}
          onChange={handleSearchChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
        <button
          className={`sb3-relative sb3-block sb3-w-9 sb3-aspect-square sb3-h-full !sb3-border-0  hover:sb3-bg-white hover:sb3-border-white hover:sb3-text-black focus:sb3-text-black focus:sb3-bg-white focus:sb3-border-white focus:sb3-outline-none `}
          type="button"
          onClick={() => props.handleSearchChange(searchTerm)}
        >
          <FaSearch/>
        </button>
      </div>
  )
}

export default SearchBar
