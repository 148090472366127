import { CMPT_CONF_CLIENT_LOGO_URL, CMPT_CONF_COMPATIO_CONF, CMPT_CONF_MAGENTO, CMPT_CONF_SHOPIFY } from 'constants/constants';
import React from 'react';
import { ImSpinner2 } from 'react-icons/im';

const LoadingComponent = ({ logoSrc = '/path/to/your/logo.png' }) => {
  return (
    <div className="sb3-flex sb3-flex-col sb3-items-center sb3-justify-center sb3-min-h-screen sb3-bg-gray-100">
      <img 
        src={(
            !!window[CMPT_CONF_COMPATIO_CONF] ? (window[CMPT_CONF_COMPATIO_CONF]?.[CMPT_CONF_MAGENTO]?.[CMPT_CONF_CLIENT_LOGO_URL] ?? window[CMPT_CONF_COMPATIO_CONF]?.[CMPT_CONF_SHOPIFY]?.[CMPT_CONF_CLIENT_LOGO_URL]) :
            '/compatio.png'
        )}
        alt="Logo" 
        className="sb3-w-40 sb3-mb-8 sb3-animate-pulse sb3-object-contain"
      />
      <ImSpinner2 className="sb3-text-4xl sb3-text-blue-500 sb3-animate-spin" />
    </div>
  );
};

export default LoadingComponent;