import { API_SUB_MODEL_ID, CATEGORY_ID, CATEGORY_NAME, FRMDATA_SMID, MODEL_CATEGORIES, MODEL_DATA, MODEL_SUB_MODEL, MODEL_SUBMODEL_ID, PAGE_FORM_DATA, SORT } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import CompatioAnalytics from "helpers/CompatioAnalyticsSb3"
import NodeHelper from "helpers/NodeHelper"
import { Dispatch, SetStateAction } from "react"
import Select, {Options} from 'react-select'
import Utils from "Services/Utils"
import { TModel } from "types"

const options = [
    { value: SORT.RECOMMENDED, label: 'Recommended', selected: true },
    { value: SORT.PRICE_ASC, label: 'Price (low to high)' },
    { value: SORT.PRICE_DESC, label: 'Price (high to low)' },
    { value: SORT.ALPHABETICAL_ASC, label: 'Alphabetical (A > Z)' },
    { value: SORT.ALPHABETICAL_DESC, label: 'Alphabetical (Z < A)' },
]

type MyProps = {
    categorySort?: string
    setCategorySort?: Dispatch<SetStateAction<string>>
}
 
const SB2Sort = (props: MyProps) => {
    const {
        sort,
        setSort,
        activeSubmodel,
        builderConfig,
        activePage,
        specs,
        modelId,
        modelSpec
    } = useAppContext()

    const sortFun = props.setCategorySort ?? setSort

    const handleSortChange = (val: typeof options[number]) => {
        const config = builderConfig()?.compatibleProducts

        sortFun(prev => {
           if(activeSubmodel) CompatioAnalytics.track(
                CompatioAnalytics.events.xrsSortByChange,
                {
                  baseFinalProductID: Utils.getClientProductSku() ?? "",
                  baseParentProductID: Utils.getClientProductSku() ?? "",
                  baseFinalProductName: (config?.productName && decodeURIComponent(config.productName)) ?? "",
                  categoryID: activeSubmodel[MODEL_SUBMODEL_ID],
                  categoryName: activeSubmodel[MODEL_DATA][MODEL_SUB_MODEL],
                  categoryPosition: (activePage?.[PAGE_FORM_DATA]?.sort((uiModel, uiModel2) => {
                    const submodel: TModel = specs[uiModel[FRMDATA_SMID]]
                    const submodel2: TModel = specs[uiModel2[FRMDATA_SMID]]
                    return +NodeHelper.checkIfSubmodelIsRequired(submodel2) - +NodeHelper.checkIfSubmodelIsRequired(submodel)
                  })?.findIndex(model => model[FRMDATA_SMID] === activeSubmodel[MODEL_SUBMODEL_ID]) ?? 0) + 1,
                  oldSortOrder: prev,
                  newSortOrder: val.value,
                },
                'SmartBuilder3',
                undefined,
                'V1',
                modelSpec?.project.ISRName,
                modelId
            );

            return val.value
        })
    } 

    return <Select 
        options={options} 
        value={
            options.find(val => val.value === (props.categorySort ?? sort)) ?? { value: SORT.RECOMMENDED, label: 'Recommended', selected: true }
        } 
        onChange={(val: any) => handleSortChange(val)} 
        isSearchable={false} 
        className="lg:sb3-w-[250px] sb3-text-xs sb3-font-bold sb3-w-full sb3-max-w-full"
        styles={{
               
                control: (provided, state) => ({
                  ...provided,
                    borderRadius: 0,
                }),
                
        }}
    />
}

export default SB2Sort