import AuthManager from "Services/AuthManager";
import Utils from "Services/Utils";
import React from "react";

function Login(props: any) {
  function handle_login() {
    let rto = "rto=" + Utils.get_current_root_url() + "callback/login";
    AuthManager.setQueryParams(window.location.search);
    AuthManager.setPathname(window.location.pathname);
    let redirect_url = "https://sams-sso.compat.io/landing" + "?m=get&" + rto;
    window.location.href = redirect_url;
  }
  React.useEffect(() => {
    handle_login();
    Utils.SetWindowTitle("Login");
  });

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col min-vh-100 d-table">
            <div className="d-table-cell align-middle">
              <div className="row align-items-center mx-auto">
                <br></br>
              </div>
              <div className="row align-items-center mx-auto">
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>

        </div>
      </div>

    </React.Fragment>
  );
}

export default Login;