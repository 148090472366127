import Cart from "components/Cart"
import Filters from "components/Filters"
import Categories from "components/Submodel/Category/Categories"
import { BUILDER_CONFIG, CATEGORY_ID, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, FIELD_ID, FRMDATA_ITEMS, FRMDATA_SMID, MODEL_CATEGORIES, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, MODEL_SUBMODEL_ID, PAGE_FORM_DATA, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_MAX_COUNT, RUNTIME_INSTANCE_MAX_QUANTITY, THEME_SUBTITLES } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
// import { AnimatePresence, m } from "framer-motion"
import NodeHelper from "helpers/NodeHelper"
import { isArray } from "lodash"
import Utils from "Services/Utils"
import { TModel, TProductCategory, TProductCategoryField } from "types"
import { TCartItem } from "types/Cart"

const ConfiguratorLayout = () => {
    const { 
        loading,
        // activeSubmodel,
        builderSpec,
        specs,
        cart,
        activePage,
        getInstances,
    } = useAppContext()

    const activeSubmodel = activePage && {
        ...specs[activePage[PAGE_FORM_DATA][0][FRMDATA_SMID]], 
        instance: getInstances(activePage[PAGE_FORM_DATA][0][FRMDATA_SMID])?.[0],
        uiModel: activePage[PAGE_FORM_DATA][0]
    }

    let selectedProducts: TCartItem[] = []

    if(
        activeSubmodel?.[MODEL_SUBMODEL_ID] 
        && activeSubmodel.instance 
        && specs[activeSubmodel?.[MODEL_SUBMODEL_ID]]?.[MODEL_DATA]?.[MODEL_CATEGORIES]?.[0]?.[CATEGORY_ID]
    ){
        selectedProducts = Utils.getSelectedProductsFromCategoryIds(cart, specs[activeSubmodel[MODEL_SUBMODEL_ID]][MODEL_DATA][MODEL_CATEGORIES].map((cat: TProductCategory) => cat[CATEGORY_ID]), activeSubmodel.instance[RUNTIME_INSTANCE_INSTANCE_ID])
    }

    const visibleNodes = activeSubmodel?.instance?.[MODEL_DYNAMIC_FIELDS] && NodeHelper.filterVisibleNodesUsingRuntime(activeSubmodel.instance[MODEL_DYNAMIC_FIELDS])

    const isFilterActive = activeSubmodel && isArray(visibleNodes?.visibleFieldIds) 
        && (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)?.[MODEL_DATA][MODEL_FIELDS]
            .filter((field) => visibleNodes.visibleFieldIds.includes(field[FIELD_ID])).length > 0
        && specs[activeSubmodel[MODEL_SUBMODEL_ID]]?.[MODEL_DATA][MODEL_CATEGORIES]
            ?.some((cat: TProductCategoryField) => (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1)
            > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID]) && (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID]))

    return (
        <>
        <div className="sb3-flex-grow sb3-space-y-4 sb3-sticky sb3-top-0 sb3-flex sb3-flex-col">

            <div className={`lg:sb3-flex lg:sb3-space-x-5 sb3-w-full sb3-flex-grow`}>
                {/* filter */}
                {/* <AnimatePresence> */}
                {
                    !loading 
                    && isFilterActive &&
                    // !activeSubmodel || !specs[activeSubmodel.id] &&
                    <div 
                        // initial={firstRender.current ? false : "initial"}
                        // animate="animate"
                        // exit = "exit"
                        className="sb3-w-[300px] sb3-max-w-full sb3-border sb3-h-min sb3-rounded sb3-px-2 sb3-pt-2 sb3-hidden lg:sb3-block sb3-bg-white"
                        // variants={firstRender.current ? {} : variants}
                    >
                        <h3 style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SUBTITLES]}}>Filter</h3>
                        
                        <Filters/>
                    </div>
                }
                {/* </AnimatePresence> */}


                {/* products */}
                <div className="sb3-w-full sb3-transition-all sb3-space-y-2 sb3-flex sb3-flex-col sb3-bg-white sb3-p-2 sb3-rounded">
                    {
                        activeSubmodel && 
                        <Categories
                            submodel = {activeSubmodel} 
                            categories = {activeSubmodel[MODEL_SUBMODEL_ID] ? (specs[activeSubmodel[MODEL_SUBMODEL_ID]]?.[MODEL_DATA][MODEL_CATEGORIES] as TProductCategoryField[])?.filter(({id}) => visibleNodes?.visibleCategoryIds.includes(id)) : []}
                            selectedProducts = {selectedProducts}
                            instance={activeSubmodel.instance}
                            uiModel={activeSubmodel.uiModel}
                        />
                    }
                    {!activePage && !loading &&
                        <p>Your Build is ready! Please click <b>Add to Cart</b> to add items to the cart</p>
                    }
                </div>
            </div>
        </div>

        {/* right side */}
        {/* cart component */}
        {
            !loading && 
            <div className="sb3-w-full sb3-max-w-screen sb3-text-right sb3-space-y-2 lg:sb3-block sb3-hidden">
                <Cart/>
            </div>
        }
        </>
    )
}

export default ConfiguratorLayout