import React from "react";
import FillButton from "./Button/FillButton";
import ModalContainer from "./Modal/ModalContainer";
import ModalHeader from "./Modal/ModalHeader";
import ModalBody from "./Modal/ModalBody";
import ModalFooter from "./Modal/ModalFooter";
import { PLACEHOLDER_IMAGE } from "constants/constants";

type MyProps = {
  showModal: {name: string}|null,
  closeModal: () => void
  onConfirm: () => void
  loading: boolean
}

const ConfigureModal = ({ showModal, closeModal, onConfirm, loading }: MyProps) => {
  return (<ModalContainer
    width={'sb3-min(800px, 100vw)'}
    isOpen={!!showModal}
    setOpen={closeModal}
  >
    <ModalHeader>
      <div className=" sb3-py-2">
        <h5 className="sb3-text-3xl sb3-font-semibold">{showModal?.name}</h5> 
        {/* <span>{totalProducts} {totalProducts === 1 ? "Product" : "Products"} found</span> */}
      </div>
    </ModalHeader>
    <ModalBody>
      <div className="sb3-flex sb3-space-x-5 sb3-text-sm">
        <div className="sb3-w-2/12 sb3-min-w-[150px]">
            <img src={PLACEHOLDER_IMAGE} alt="Product" className="sb3-aspect-square sb3-h-auto sb3-max-w-full"/>
        </div>

        <div className="sb3-flex-grow sb3-space-y-3">
            <p>Please confirm these options:</p>

            <div className="sb3-space-y-3">
            {
                [...Array(3)].map((_, index) => (

                <div className="sb3-space-y-2" key={index}>
                    <p className="sb3-font-bold sb3-uppercase">Please Choose a backing (+ $9.95):</p>

                    {
                        [...Array(3)].map((_, key) => (
                            <div className="sb3-space-x-3 sb3-flex" key={key}>
                                <input type="radio"></input>
                                <label>20 LB DACRON</label>
                            </div>
                        ))
                    }
                </div>
                ))
            }
            </div>
        </div>

      </div>
    </ModalBody>
    <ModalFooter>
        <div className="sb3-flex sb3-justify-end sb3-space-x-3">
            <FillButton 
                className="sb3-px-5"
                onClick={() => onConfirm()}
                disabled = {loading}
            >Add to Outfit</FillButton>
        </div>
    </ModalFooter>
  </ModalContainer>
  )
}

export default ConfigureModal