import Utils from "Services/Utils"
import { useEffect, useState } from "react"

/**
 * paginate data based on page and limit
 * 
 * @param {any[]} data
 * @param {nubmer} defaultPage - (optional) current page value
 * @param {number} defaultLimit - (optional) current limit value
 * @param {manual|auto} type - (optional) type of operation
 */
const usePaginate = <T extends any>(
    data: T[], 
    defaultPage?: number, 
    defaultLimit?: number,
    type?: "manual"
) => {
    const [rows, setRows] = useState<T[]>([])
    const [page, setPage] = useState(defaultPage ?? 1)
    const [limit, setLimit] = useState(defaultLimit ?? 50)

    useEffect(() => {
        if(defaultPage) 
            setPage(defaultPage)
    }, [defaultPage])

    useEffect(() => {
        if(defaultLimit) 
            setLimit(defaultLimit)
    }, [defaultLimit])

    useEffect(() => {
        if(type === "manual") return setRows(data.slice(0, limit ?? 50))
        if(!page) return setRows(data.slice(0, limit ?? 50))

        const {start, end} = Utils.getStartEndFromPageAndLimit(page, limit, 50)
        setRows(data.slice(start, end))
    }, [data, limit, page])
    
    return {
        rows,
        page,
        limit,
        setPage,
        setLimit
    }
}

export default usePaginate