import { TTableHeader } from "types/components/Table";

/**
 * Constants file
 * 
 * Reusable values stored as constants
 * 
 * Api key constants
 * - Values that are could change potentially are stored here
 *  so only minimal changes are required
 */

export const THEMES = {
    APPLICO: "layout1",
    CUMMINS: "layout2",
    CONFIGURATOR: 'layout3',
    TEST: 'test',
    LINEAR: 'layout4',
}

export const PLACEHOLDER_IMAGE = "https://cdn1.vectorstock.com/i/thumb-large/50/20/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg";
export const BLANK_IMAGE_URL_SQ ="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
export const SVG_TEST ="https://dev.w3.org/SVG/tools/svgweb/samples/svg-files/poi.svg"

// page routes
export const PAGES = {
    CONFIGURE: '/configure',
    PREVIEW: '/preview',
    LOGIN: "/",
    LOGIN_CALLBACK: "/callback/login"
}

// field types
export const FIELD_TYPES = {
    MULTI_CHOICE: "Multi-choice",
    CATEGORY: "Category",
    CONNECTOR: "Connector",
    SUB_MODEL: "Submodel",
    MODEL: "Model",
    FRACTIONS: "Fraction",
    DECIMAL: "Decimal",
    BOOLEAN: "Boolean",
    INTEGER: "Integer",
    TEXT: "Field",
}

export const UI_FIELD_TYPES = {
    MULTI_CHOICE: {
        CHECKBOX: "Checkbox",
        RADIO: "Radio Button",
        SELECT: "Drop down",
        CHIPS: "Chip",
        GRID: "Grid"
    },
    TEXT: {
        LABEL: "Label"
    }
}


// UI Definition key map to api result values
export const DEFN_INDUSTRY = "industry"
export const DEFN_CHANNEL = "channel"
export const DEFN_ID = "id"
export const DEFN_PROJECT = "project"
export const DEFN_CLASSES = "classes"

export const FIELD_ID = "id"
export const FIELD_TYPE = "type"
export const FIELD_NAME = "name"
export const FIELD_CATEGORY_NAME = "categoryName"
export const FIELD_CATEGORY_VISIBLE_FLAG = "isCategoryVisible"
export const FIELD_DATA_CONFIGURATION = "dataConfiguration"
export const FIELD_CATEGORIES= "categories"
export const FIELD_BELONGS_TO = "belongsToDataModel"
export const FIELD_REQUIRED = "required"
export const FIELD_ADDITIONAL_DATA = "additionalData"
export const FIELD_RULES = "rules"
export const FIELD_MIN_COUNT = "minCount"
export const FIELD_MAX_COUNT = "maxCount"
export const FIELD_MIN_QUANTITY = "minQuantity"
export const FIELD_MAX_QUANTITY = "maxQuantity"

export const CONF_CLASS = "class"
export const CONF_MAX_CHAR_COUNT = "maxCharacterCount"
export const CONF_DEFAUT_VALUE = "defaultValue"
export const CONF_CHOICES = "choices"
export const CONF_MIN = "min"
export const CONF_MAX = "max"
export const CONF_INCREMENT = "increment"
export const CONF_LABEL = "label"
export const CONF_DECIMAL_POINTS = "decimalPoints"
export const CONF_SELECT_TYPE = "selectType"
export const CONF_PROP_TYPE = "propType"
export const CONF_FILTER = "filter"
export const CONF_CAT_NAME = "categoryName"
export const CONF_CAT_ID = "categoryId"
export const CONF_DECIMAL_CONVERSION = "decimalConversion"
export const CONF_DECIMAL_MIN = "decimalMin"
export const CONF_DECIMAL_MAX = "decimalMax"
export const CONF_UNITS = "units"
export const CONF_LABEL_WHOLE_NUMBER = "labelWholeNumber"
export const CONF_LABEL_FRACTION = "labelFraction"
export const CONF_VALUE = "value"
export const CONF_REQUIRED = "fieldRequired"
export const CONF_ENABLE_CALCULATION = "enableCalculation"
export const CONF_FILTER_AVAILABLE = "filterAvailable"
export const CONF_FILTER_RANGE = "filterRange"
export const CONF_SVG = "svg"
export const CONF_ATTR_ID = "attributeId"
export const CONF_ATTR_NAME = "attributeName"


export const FILTER_CAT_ID = "categoryId"
export const FILTER_ATTR_ID = "attributeId"

export const CHOICE_ID = "av_id"
export const CHOICE_IS_DISABLED = "isDisabled"
export const CHOICE_NAME = "av_value"
export const CHOICE_DESCRIPTION = "product_description"
export const CHOICE_PRODUUCT_ID = "product_id"
export const CHOICE_IS_SELECTED = "isSelected"
export const CHOICE_IMAGE = "product_image"
export const CHOICE_IMAGE_CONDITIONS = "image_conditions"
export const CHOICE_IMAGE_CONDITIONS_OR = "image_conditions_or"
export const CHOICE_HELP = "Help"
export const CHOICE_HINT = "Hint"
export const CHOICE_HAS_HELP = "HasHelp"
export const CHOICE_HAS_HINT = "HasHint"

export const ADD_DESCRIPTION = "description"
export const ADD_INFO = "info"
export const ADD_HOVER_TEXT = "hoverText"
export const ADD_REF_LINK = "refLink"

export const PROJECT_ID = "id"
export const SESSION_ID = "sessionID"
export const DATA_MODELS = "dataModels"
export const ISR_NAME = "ISRName"
export const MODEL_SPEC = "modelSpec"
export const RUNTIME_SPEC = "runtimeSpec"
export const UI_SPEC = "uiSpec"
export const CART_INFO = "cart_info"
export const IS_ERROR = "isError"
export const ERROR = "error"
export const ERROR_MESSAGE = "errorMsg"

export const MODEL_TYPE = "type"
export const MODEL_DATA = "data"
export const MODEL_CUSTOM_LABEL_FLAG = "canSetCustomLabel"

export const MODEL_SUB_MODEL = "name"
export const MODEL_SUBMODEL_ID = "id"
export const MODEL_INSTANCE_ID = "instance_id"
export const MODEL_FIELDS = "fields"
export const MODEL_DYNAMIC_FIELDS = "dynamic_fields"
export const MODEL_CONNECTED_IDS= "connected_inst_ids"
export const MODEL_CLASS = "class"
export const MODEL_CONNECTORS = "connectors"
export const MODEL_UI_CATEGORIES = "categories"
export const MODEL_CATEGORIES = "productCategories"
export const MODEL_MIN_INSTANTIATION = "minInstantiation"
export const MODEL_MAX_INSTANTIATION = "maxInstantiation"
export const MODEL_REQUIRED = "required"
export const MODEL_MIN = "min"
export const MODEL_MAX = "max"

export const CONNECTOR_TYPE = "type"
export const CONNECTOR_ID = "id"
export const CONNECTOR_DATA = "data"
export const CONNECTOR_DATA_TYPE = "type"
export const CONNECTOR_DATA_NAME = "name"
export const CONNECTOR_DATA_BELONGS_TO = "belongsToDataModel"
export const CONNECTOR_SUBMODELS = "submodels"
export const CONNECTOR_IS_CONSUMABLE = "isConsumable"
export const UI_CONNECTOR_DATA_NAME = "connectorName"
export const UI_CONNECTOR_VISIBLE = "isConnectorVisible"

export const CLASS_ID = "id"
export const CLASS_NAME = "name"
export const CLASS_TYPE = "type"
export const CLASS_COLOR = "colour"

export const API_CATEGORY_NAME = "category_name"
export const API_SUBMODEL_ID = "sub_model_id"
export const API_SESSION_KEY = "session_id"
export const API_VARIANT_LIST = "variants_list"
export const API_MODEL_KEY = "model_id"
export const API_FIELD_KEY = "field_id"
export const API_CHOICE_KEY = "field_value"
export const API_INSTANCE_ID = "instance_ID"
export const API_PRODUCT_IDS = "product_ids"
export const API_PRODUCT_ID = "product_id"
export const API_PRODUCTS = "products"
export const API_VARIANT_ID = "variant_id"
export const API_MERCHANT_KEY = "merchant_key"
export const API_START = "start"
export const API_END = "end"
export const API_INSTANCE_DATA = "instance_data"
export const API_IS_FORCED = "isForced"
export const API_IS_DISABLED = "isDisabled"
export const API_IS_DESELECT = "isDeselect"
export const API_SUB_MODEL_ID = "sub_model_ID"
export const API_INSTANCE_FROM = "instance_from"
export const API_INSTANCE_TO = "instance_to"

export const RES_CONFLICT_CHOICES = "conflictChoices"
export const RES_MESSAGE = "conflictChoices"
export const RES_CONFLICT_FLAG = "isConflict"
export const CONFLICT_CATEGORY_NAME = "categoryName"
export const CONFLICT_CATEGORY_SUBMODEL = "subModel"

export const CATEGORY_ID = 'id'
export const CATEGORY_NAME = 'name'

export const CONFIGURATION_TABLE_HEADERS: TTableHeader[] = [
    {name: "Field", column: "field_name"},
    {name: "Value", column: "choice"}
]

// api routes
export const API_CATEGORIES = 'api/categories'
export const API_MANUFACTURERS = 'api/manufacturers'
export const API_CONFIGURE = 'api/configurator/load'
export const API_INPUT = 'api/configurator/input'
export const API_CREATE_INSTANCE = 'api/configurator/instance'
export const API_CREATE_CONNECTION = 'api/configurator/addconnection'
export const API_DROP_CONNECTION = 'api/configurator/dropconnection'
export const API_INSTANCE_INPUT = 'api/configurator/instance'
export const API_INSTANCE_RESULT = 'api/configurator/result'
export const API_PREVIEW_LOAD = 'api/configurator/preview/load'
export const API_PRODUCT_INFO = 'api/configurator/product/info'
export const API_VARIANT_CHECK = 'api/configurator/variant/model'

// API related keys
export const API_KEY_VERSION = "version"
export const API_KEY_PROJECT_ID = "project_id"

// API values
// export const API_VALUE_PROJECT_ID = "c53d82e0c99e11eebd985d62fa84fc6f" // smooth star 
// export const API_VALUE_PROJECT_ID = "d702ff76ccc011eea78f4f6872b604b2" // smooth star clone
export const API_VALUE_PROJECT_ID = "a4b5528a93544a6d9db61b33b267749b" // cummins
// export const API_VALUE_PROJECT_ID = "99a35d1a7f0911eeb974ca360f367ccc" // Tb-Reeb demo
// export const API_VALUE_PROJECT_ID = "71eba28ed08311eeb7595276dc7fa817" // cycling
// export const API_VALUE_PROJECT_ID = "bcad336cd21511eeb72166a9adb2497e" // new reeb model
// export const API_VALUE_PROJECT_ID = "a9955cd4d15a11eeac949ee069e25fbf" // test equity
// export const API_VALUE_PROJECT_ID = "2f297130dab311eeb609f6bfb3bb4870" // galco new
// export const API_VALUE_PROJECT_ID = "3dba33fcd7b011eeb709d2d91587897c" // galco abhi
// export const API_VALUE_PROJECT_ID = "774bb14cccfb481592dd12c7fd13068c" // reeb 1 my version
// export const API_VALUE_PROJECT_ID = "d8bb38a6f58811ee871b3e646105ec03" // galco qa
export const API_VALUE_VERSION = "live"

// UI Defn
export const STATE_SHOW = "show"
export const STATE_HIDE = "hide"
export const STATE_DISABLE = "disable"

export const DEFN_RULES = "rules"
export const DEFN_ORIENTATION = "orientation"
export const DEFN_BUILDER_PAGES = "builderPages"
export const DEFN_PAGES = "pages"


export const FIELD_SHOW_TYPE = "fieldType"
export const FIELD_STATE = "state"
export const FIELD_FIELD_SELECTION = "fieldTargetSelection"
export const FIELD_RANK = "rank"
export const FIELD_VISIBLE = "isFieldVisible"
export const FIELD_HIDDEN = "hidden"
export const FIELD_ANSWER_SELECTION = "answerTargetSelection"
export const FIELD_BUTTON_LABEL = "productLabel"
export const FIELD_CONDITION_STATE = "conditionState"
export const FIELD_CONDITION_FLAG = "enableCondition"
export const UI_FIELD_NAME = "fieldName"

export const MODEL_HIDDEN = "hidden"
export const MODEL_STATE = "state"
export const MODEL_FIELD_SELECTION = "field_selection"
export const MODEL_RANK = "rank"
export const UI_MODEL_NAME = "submodelName"
export const MODEL_ANSWER_SELECTION = "answer_selection"

export const PAGE_NAME = "pageName"
export const PAGE_ID = "id"
export const PAGE_LABEL = "pageLabel"
export const PAGE_AUTO_ADVANCE = "isAutoAdvancement"
export const PAGE_LAYOUT = "pageLayout"

// Runtime spec 
export const RUNTIME_INSTANCES = "instances"
export const RUNTIME_INSTANCE_BASE_ID = "class_type"
export const RUNTIME_INSTANCE_REQUIRED = "required"
export const RUNTIME_INSTANCE_FIELD_REQUIRED = "isRequired"
export const RUNTIME_INSTANCE_INSTANCE_ID = "id"
export const RUNTIME_INSTANCE_FIELD_TYPE = "type"
export const RUNTIME_INSTANCE_FIELD_VALUE = "value"
export const RUNTIME_INSTANCE_FIELD_AGGREGATE = "aggregate_on"
export const RUNTIME_INSTANCE_FIELD_CLASS_ID = "class_id"
export const RUNTIME_INSTANCE_FIELD_OPERATOR = "operator"
export const RUNTIME_INSTANCE_FIELD_CONNECTIONS = "connections"
export const RUNTIME_INSTANCE_EXPRESSION = "eval_expression"
export const RUNTIME_INSTANCE_IS_INPUT = "isInput"
export const RUNTIME_INSTANCE_CHOICES = "choices"
export const RUNTIME_INSTANCE_MIN_COUNT = "min_count"
export const RUNTIME_INSTANCE_FIELD_NAME= "name"
export const RUNTIME_INSTANCE_MAX_COUNT = "max_count"
export const RUNTIME_INSTANCE_MIN_QUANTITY = "min_quantity"
export const RUNTIME_INSTANCE_MAX_QUANTITY = "max_quantity"
export const RUNTIME_INSTANCE_IS_HIDDEN = "is_hidden"
export const RUNTIME_INSTANCE_ELEMENT_TYPE = "element_type"

/** BUILDER SPEC */
export const BUILDER_CONFIG = "config"
export const BUILDER_PAGES = "pages"

export const PAGE_FORM_DATA = "formData"

export const FRMDATA_CONFIG = "config"
export const FRMDATA_ID = "id"
export const FRMDATA_SMID = "smid"
export const FRMDATA_ITEMS = "items"
export const FRMDATA_ADDITIONAL_DATA = "additionalData"
export const FRMDATA_ANSWER_TARGET_SELECTION = "answerTargetSelection"
export const FRMDATA_BELONGS_TO = "belongsToDataModel"
export const FRMDATA_CATEGORY_NAME = "categoryName"
export const FRMDATA_CONDITION_STATE = "conditionState"
export const FRMDATA_DATA_CONF = "dataConfiguration"
export const FRMDATA_DATA = "data"
export const FRMDATA_ENABLE_CONDITION = "enableCondition"
export const FRMDATA_FIELD_EXCLUSION = "fieldExclusion"
export const FRMDATA_FIELD_INCLUSION = "fieldInclusion"
export const FRMDATA_FIELD_NAME = "fieldName"
export const FRMDATA_FIELD_TARGET_SELECTION = "fieldTargetSelection"
export const FRMDATA_FIELD_TYPE = "fieldType"
export const FRMDATA_CATEGORY_VISIBILITY = "isCategoryVisible"
export const FRMDATA_FIELD_VISIBILITY = "isFieldVisible"
export const FRMDATA_MODEL_FIELD_TYPE = "modelFieldType"
export const FRMDATA_NAME = "name"
export const FRMDATA_RANK = "rank"
export const FRMDATA_RULES = "rules"
export const FRMDATA_TYPE = "type"
export const FRMDATA_BUTTON_LABEL = "productLabel"
export const FRMDATA_GROUP_LABEL = "groupLabel"
export const FRMDATA_DISPLAY_NAME = "displayName"


export const CONFIG_CONFIGURED = "builderConfigured"
export const CONFIG_CREATED = "builderCreated"
export const CONFIG_SAVED = "builderSaved"
export const CONFIG_CUSTOMIZATION = "customization"

export const CUSTOMIZATION_CUSTOM_CSS = "customCss"
export const CUSTOMIZATION_NAME = "name"
export const CUSTOMIZATION_PLATFORM = "platform"
export const CUSTOMIZATION_RESPONSIVE_PREF = "responsivePreference"
export const CUSTOMIZATION_TEMPLATE = "template"
export const CUSTOMIZATION_THEME_SETTINGS = "themeSettings"
export const CUSTOMIZATION_VOICE_ENABLED = "voiceEnabled"
export const CUSTOMIZATION_NAVIGATION = "navigation"
export const CUSTOMIZATION_VIEW_PREF = "viewPref"

export const THEME_BACKGROUND = "background"
export const THEME_ONLINE_BUTTON = "onlineButton"
export const THEME_SECONDARY = "secondaryBackground"
export const THEME_FILL_BUTTON_BG = "solidButtonBackground"
export const THEME_FILL_BUTTON_CLR = "solidButtonLabel"
export const THEME_SUBTITLES = "subtitles"
export const THEME_TITLE = "title"
/** BUILDER SPEC END */

export const SORT = {
    SEPERATE: "seperate",
    SEPERATE_ASC: "s_asc",
    SEPERATE_DESC: "s_desc",
    ASC: "asc",
    DESC: "desc",
    RECOMMENDED: "compatioscore_descending",
    PRICE_ASC: "price_ascending",
    PRICE_DESC: "price_descending",
    ALPHABETICAL_ASC: "alphabetical_ascending",
    ALPHABETICAL_DESC: "alphabetical_descending",
}

export const VALUE_TO_INSTANTIATION_MAP_APPLICO = {
    "4d442c04dd1b11ee82dee2cb2aca0ec4": ["0bfba840d22011eea155fa3607c0dbb1"],
    "ee609b18dd1b11ee99a0e2cb2aca0ec4": ["d2cac4bcd22311eebceefa3607c0dbb1", "d6e313aad21511eeba5666a9adb2497e"],
    "ee5c07a6dd1b11ee99a0e2cb2aca0ec4": ["d2cac4bcd22311eebceefa3607c0dbb1", "d6e313aad21511eeba5666a9adb2497e"],
    "ee653f92dd1b11ee99a0e2cb2aca0ec4": ["d2cac4bcd22311eebceefa3607c0dbb1", "d6e313aad21511eeba5666a9adb2497e", "d6e313aad21511eeba5666a9adb2497e"],
    "4d383700dd1b11ee82dee2cb2aca0ec4": ["d2cac4bcd22311eebceefa3607c0dbb1"],
    "4d48a3badd1b11ee82dee2cb2aca0ec4": ["d6e313aad21511eeba5666a9adb2497e"],
    "4d3f4432dd1b11ee82dee2cb2aca0ec4": ["401c1552d21a11eeb588fa3607c0dbb1"],
}

export const VALUE_TO_INSTANTIATION_MAP_CUMMINS = {
    "39803c06852011eea08e5632d69d032d": ["bca40c04851911eebb915632d69d032d", "fa0cb78e851a11eea5225632d69d032d"],
}

export const SELECT_TYPE = {
    SINGLE: "Single-select",
    MULTI: "Multi-select"
}


// SB3 configs for magento and shopify - COMPATIO CONFIG
export const CMPT_CONF_PRICE = "price"
export const CMPT_CONF_COMPARE_PRICE = "compareAtPrice"
export const CMPT_CONF_CURRENCY = "currency"
export const CMPT_CONF_PRDT_IMAGE = "productImage"
export const CMPT_CONF_PRICE_ENGINE = "priceEngineApi"
export const CMPT_CONF_PRDT_SKU = "productSku"
export const CMPT_CONF_PRDT_MODEL_NUMBER = "modelNumber"
export const CMPT_CONF_PRDT_NAME = "productName"
export const CMPT_CONF_PRDT_PARENT_CATEGORY = "parentCategory"
export const CMPT_CONF_STORE = "store"
export const CMPT_CONF_PRDT_DCCONFIG = "dcConfig"
export const CMPT_CONF_MODEL_ID = "modelId"
export const CMPT_CONF_DEFAULT = "default"
export const CMPT_CONF_BUILDER_TYPE = "builderType"
export const CMPT_CONF_DETAILS = "details"
export const CMPT_CONF_DETAILS_TITLE = "title"
export const CMPT_CONF_DETAILS_BTN_TXT = "buttonText"
export const CMPT_CONF_DETAILS_DESC = "description"
export const CMPT_CONF_DETAILS_IMAGE_URL = "imageUrl"

export const CMPT_CONF_COLOR_COMBO = "colorCombination"

export const CMPT_CONF_CLIENT_END_POINT = "apiEndpointUrl"
export const CMPT_CONF_CLIENT_BASE_URL = "baseUrl"
export const CMPT_CONF_CLIENT_URL_SUFFIX = "urlSuffix"
export const CMPT_CONF_CLIENT_TARGET_GRAPH = "targetGraph"
export const CMPT_CONF_CLIENT_MERCHANT_KEY = "merchantKey"
export const CMPT_CONF_CLIENT_MODEL_ID = "modelId"
export const CMPT_CONF_CLIENT_LOGO_URL = "logoUrl"
export const CMPT_CONF_CLIENT_SHOPIFY_ACCESS_TOKEN = "shopifyStorefrontAccessToken"
export const CMPT_CONF_CLIENT_COLLECTION_HANDLE = "collectionHandle"
export const CMPT_CONF_CLIENT_PREFILTERED = "prefiltered"
export const CMPT_CONF_CLIENT_APPLY_FILTER = "applyfiltered"
export const CMPT_CONF_CLIENT_V2KEY = "v2Key"
export const CMPT_CONF_CLIENT_PRICE_ENGINE_API = "priceEngineApi"


export const CMPT_CONF_COMPATIO_ANALYTICS_LOADED_FN = "compatioAnalyticsLoaded"
export const CMPT_CONF_COMPATIO_ANALYTICS = "CompatioAnalytics"
export const CMPT_CONF_COMPATIO_CONF = "compatioConfig"
export const CMPT_CONF_COMPATIBLE_PRODUCTS = "compatibleProducts"
export const CMPT_CONF_COLOR_CONF = "dcColorConfig"
export const CMPT_CONF_MAGENTO = "magento"
export const CMPT_CONF_SHOPIFY = "shopify"
export const CMPT_CONF_LOCALE = "locale"
export const CMPT_CONF_API_KEY = "compatioApiKey"
export const CMPT_CONF_PLATFORM = "platForm"

export const RESULT_PRDT_UPC = "upc"
export const RESULT_PRDT_DESC = "description"
export const RESULT_MANUFACTURER = "manufacturerName"
export const RESULT_PRDT_UID = "uid"
export const RESULT_PRDT_IMAGE = "product_image_detail"
export const RESULT_VARIANT_NAME = "variantTitle"
export const RESULT_PRDT_URL_IMAGE = "url_image"
export const RESULT_PRDT_CATEGORY_ID = "catgeory_id"
export const RESULT_PRDT_NAME = "name"
export const RESULT_PRDT_PRICE = "min_ad_price"
export const RESULT_PRDT_SKU = "sku"
export const RESULT_PRDT_QTY = "quantity"
export const RESULT_PRDT_CATEGORY = "category"
export const RESULT_PRDT_CATEGORY_NAME = "categoryName"
export const RESULT_PRDT_INSTANCEID = "instanceId"
export const RESULT_PRDT_MODEL_NO = "model_number"
export const RESULT_PRDT_OPTIONS = "options"
export const RESULT_PRDT_STOCK_STATUS = "stock_status"
export const RESULT_PRDT_URL = "product_url"
export const RESULT_IS_AVAILABLE = "isAvailable"
export const RESULT_PARENT_ID = "parent_product_id"

export const PRDT_DISPLAY_TYPES = {
    VERTICAL: "vertical",
    HORIZONTAL: "horizontal",
}

export const CATEGORY_DISPLAY_TYPES = {
    GRID: "grid",
    LIST: "list",
}

export const RUNTIME_ELEMENT_TYPES = {
    FIELD: "field",
    CATEGORY: "category"
}

export const PAGE_LAYOUTS = {
    DEFAULT: "Default",
    GUIDED_SELLING: "Guided Selling"
}

export const ANCHOR_TYPES = {
    BUTTON: "button",
    ANCHOR: "anchor",
    BANNER: "banner"
}

export const URL_ENV_MAP = {
    demo: "https://isr-api-test.compatio.net/",
    prod: "https://isr-api.compatio.net/"
}