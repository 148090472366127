import ProductHelper from "helpers/ProductHelper"
import { FaCheckCircle } from "react-icons/fa"
import { TResultProduct } from "types"

type MyProps = {
    product: TResultProduct
}

const ProductStockStatus = ({product}: MyProps) => {
    const stockStatus = ProductHelper.checkIfProductInStockClient(product) === undefined ? ProductHelper.checkIfProductInStock(product) : ProductHelper.checkIfProductInStockClient(product) === "IN_STOCK"

    return (
        <>
            {
                stockStatus && <FaCheckCircle className="sb3-text-green-800 sb3-text-base"/>
            }
            <span className="sb3-truncate sb3-text-sm">{stockStatus ? "IN STOCK" : "Available For Back Order"}</span>
        </>
    )
}

export default ProductStockStatus