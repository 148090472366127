import { isArray } from "lodash"
import { ReactNode, useEffect, useRef, useState } from "react"
import { TAccordionProvided } from "types/components/Accordion"

type MyProps = {
    children: (provided: TAccordionProvided) => ReactNode,
    initialState?: boolean
    disabled?: boolean,
    resetOn?: any[]
}

/**
 * Accordion layout
 * 
 * ----------------------------------------------------------------
 * example: 
    <AccordionLayout>
    {(provided) => (
    <>
        <AccordionHeader {...provided.getHeaderProps()}>
            {headerComponent}
        </AccordionHeader>
    
        <AccordionBody {...provided.getBodyProps()}>
             {bodyComponent}
        </AccordionBody> 
    </>
    )}
    </AccordionLayout>
 */
const AccordionLayout = ({children, disabled, initialState, resetOn}: MyProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(!disabled ? (initialState ?? false) : disabled)
    const resetOnRef = useRef<any>()

    useEffect(() => {
        setIsOpen(!disabled ? (initialState ?? false) : disabled)
    }, [disabled, initialState])

    useEffect(() => {
        if(!isArray(resetOn) || resetOn.length === 0 || JSON.stringify(resetOnRef.current) === JSON.stringify(resetOn)) return
        resetOnRef.current = resetOn

        setIsOpen(!disabled ? (initialState ?? false) : disabled)
    }, [resetOn])

    const provided: TAccordionProvided = {
        isOpen: isOpen,

        getHeaderProps: () => {
            return {
                isOpen: isOpen,
                setIsOpen: setIsOpen,
                disabled: disabled
            }
        },

        getBodyProps: () => {
            return {
                isOpen: isOpen
            }
        }
    }

    return (<>{children(provided)}</>)
}

export default AccordionLayout