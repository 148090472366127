import { SORT } from 'constants/constants'
import { useAppContext } from 'contexts/AppContext'
import Select from 'react-select'

const seperateOptions = [
  { value: SORT.SEPERATE_ASC, label: 'Name - Asc' },
  { value: SORT.SEPERATE_DESC, label: 'Name - Desc' },
]

const notSeperateOptions = [
    { value: SORT.ASC, label: 'Name - Asc' },
    { value: SORT.DESC, label: 'Name - Desc' },
]

const groups = [
    { label: "Seperate", options: seperateOptions},
    { label: "Not-Seperate", options: notSeperateOptions}
]

const Sort = () => {
    const {
        sort,
        setSort
    } = useAppContext()

    return <Select 
        options={groups} 
        defaultValue={
            notSeperateOptions.find(val => val.value === sort) || 
            seperateOptions.find(val => val.value === sort)
        } 
        onChange={(val: any) => setSort(val.value)} 
        isSearchable={false} 
        className="sb3-z-50 sb3-w-[250px] sb3-text-xs"
    />
}

export default Sort