import { Draggable, DraggableChildrenFn, OnDragEndResponder } from "react-beautiful-dnd";
import { useState } from "react";
import DragAndDropHeaderItem from "./DragAndDropHeaderItem";
import * as types from "types/components/TableHeaderControlModal";
import ModalHeader from "components/Modal/ModalHeader";
import BorderedButton from "components/Button/BorderedButton";
import ModalContainer from "components/Modal/ModalContainer";
import ModalBody from "components/Modal/ModalBody";
import BorderlessButton from "components/Button/BorderlessButton";
import DragAndDropContainer from "components/DragAndDrop/DragAndDropContainer";
import ModalFooter from "components/Modal/ModalFooter";
import FillButton from "components/Button/FillButton";
import { TTableHeader } from "types/components/Table";
import { BiRotateLeft } from 'react-icons/bi';

/**
 * Modal to manage headers of table organize and hide/display header
 * columns. All changes are persisted locally, only on save will 
 * save the changes globally.
 * 
 * @param props.headers - current header properties
 * @param props.defaultHeaders  - default headers to reset
 * @param props.setHeaderControlOpen - function to toggle on/off modal state(for closing)
 * @param props.handleSave - save function new headers passed as param
 * @param props.headerControlOpen - state of modal on/off
 * @returns 
 */
const TableHeaderControlModal: types.TTableHeaderControlModal = ({
    headers, 
    defaultHeaders, 
    setHeaderControlOpen, 
    handleSave, 
    headerControlOpen, 
}) => {
    const [_headers, _setHeaders] = useState<TTableHeader[]>(JSON.parse(JSON.stringify(headers)))
    const [selected, setSelected] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false)

    let draggableComponent: (className?:string) => DraggableChildrenFn;

    // set display to false on all headers
    const handleRemoveAll: types.ThandleRemoveAll  = (e) => {
        e.preventDefault()

        _setHeaders((prevHeaders) => {
            let nextHeaders = [...prevHeaders]

            nextHeaders.map((header) => {
                header.display = false
            })
            return nextHeaders
        })
    }

    // handle checkbox changes
    const handleSelect: types.ThandleSelect = (e, id) => {
        if(e.target.checked) return setSelected(prevSelected => ([...prevSelected, id]))
        setSelected(prevSelected => {
            let nextSelected = [...prevSelected]

            const index = nextSelected.indexOf(id)
            nextSelected.splice(index, 1)

            return nextSelected
        })
    }

    // set display to true for selected headers
    const handleAdd: types.ThandleAdd = (e) => {
        _setHeaders((prevHeaders) => {
            let nextHeaders = [...prevHeaders]

            nextHeaders.map((header, index) => {
                if(selected.includes(header.id as number) || selectAll) {
                    // this is to ensure space-y does not effect hidden elements
                    const element = document.querySelector(`div[data-rbd-draggable-id="columnArrange${index}"]`) as HTMLDivElement
                    if(element) element.hidden = false
                    header.display = true
                }
            })
            return nextHeaders
        })

        setSelected([])
        setSelectAll(false)
    }

    // set display to false index header
    const handleRemove: types.ThandleRemove = (e, index) => {
        e.preventDefault()

        const element = document.querySelector(`div[data-rbd-draggable-id="columnArrange${index}"]`) as HTMLDivElement
        // hidden elements doesn't get effected with space-y
        if(element) element.hidden = true

        _setHeaders(prevHeaders => {
            let nextHeaders = [...prevHeaders]
            nextHeaders[index].display = false
            return nextHeaders
        })
    }

    // toggle select all button
    const handleSelectAll: types.ThandleSelectAll = (e) => {
        e.preventDefault()
        setSelectAll((prev) => !prev)
    }

    // reset headers to default headers
    const handleResetDefault: types.ThandleResetDefault = (e) => {
        e.preventDefault()
        _setHeaders(JSON.parse(JSON.stringify(defaultHeaders.current)))
    }

    /**
     * Change order of the headers on drag and drop
     */
    const onDragEnd: OnDragEndResponder = ({source, destination}) => {
        if(source.index == null || destination?.index == null) return
        _setHeaders((prevHeaders) => {
            let temp = [...prevHeaders]
            const removedHeader = temp.splice(source.index, 1)[0]
            if(!removedHeader) return prevHeaders
            const nextHeaders = [
                ...temp.slice(0, destination.index),
                removedHeader,
                ...temp.slice(destination.index)
            ]
            return nextHeaders
        })
    }

    // save changes and close modal
    const handleUpdate:types.ThandleUpdate = (e) =>{
        e.preventDefault()
        handleSave(_headers)
        setHeaderControlOpen(false)
    }

    draggableComponent = DragAndDropHeaderItem(_headers, handleRemove)

    return(
        <ModalContainer
        isOpen={headerControlOpen}
        setOpen={() => setHeaderControlOpen(false)}
        >
        {/* header */}
        <ModalHeader>
            <div className={`sb3-flex sb3-justify-between sb3-items-center`}>
            <h3 className={`sb3-font-bold`}>Show/Hide Column</h3>
            <BorderedButton onClick={handleResetDefault} type="button"> 
                    <span className={`sb3-flex sb3-items-center sb3-space-x-2`}>
                    <BiRotateLeft/>
                    <span>Reset to default</span>
                </span>
            </BorderedButton>
            </div>
        </ModalHeader>
    
        <ModalBody>
            <div className={`sb3-flex sb3-justify-between sb3-space-x-7`}>
                {/* left */}
                <div className={`sb3-w-[330px]`}>
                    <div className={`sb3-flex sb3-justify-between sb3-items-center`}>
                        <span className={`sb3-font-bold sb3-text-slategray`}>Available</span>
                        <BorderlessButton onClick={handleSelectAll} type="button">
                            <span>Select All</span>
                        </BorderlessButton> 
                    </div>
    
                    <div className={`sb3-w-full sb3-border sb3-rounded-sm sb3-h-[319px] sb3-min-w-max sb3-p-2 sb3-space-y-2 sb3-overflow-auto`}>
                        {
                            _headers.filter((header) => !header.display).map((header, key) => (
                                <div key={key} className={`sb3-flex sb3-items-center sb3-space-x-3 sb3-p-1.5 sb3-px-2.5`}>
                                    <input type="checkbox" 
                                        onChange={(e) => handleSelect(e, header.id as number)} 
                                        checked={(selected?.includes(header.id as number) || selectAll)}
                                        />
                                    <p className={`sb3-leading-5 sb3-max-w-[220px] sb3-truncate`}>{header.name}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {/* center */}
                <div className={`sb3-flex sb3-items-center sb3-w-min`}>
                    <BorderedButton onClick={handleAdd} type="button"> 
                        Add
                    </BorderedButton>
                </div>
    
                {/* right - drag and drop contents */}
                <div className={`sb3-w-[330px] sb3-relative`}>
                    <div className={`sb3-flex sb3-justify-between sb3-items-center`}>
                        <span className={`sb3-font-bold sb3-text-slategray`}>Selected</span>
                        <BorderlessButton onClick={handleRemoveAll} type="button">
                            <span>Remove All</span>
                        </BorderlessButton> 
                    </div>
    
                    <div className={`sb3-max-w-full`}>
                        <DragAndDropContainer id="table-header-dnd" onDragEnd={onDragEnd} renderItem={draggableComponent("")}>
                            {
                                _headers.map((header, index) =>  (
                                <Draggable key={"columnArrange" + index} index={index} draggableId={"columnArrange" + index}>
                                    {
                                        (provided, snapshot, rubric) => {
                                            return draggableComponent(!header.display ? "sb3-hidden" : undefined)(provided, snapshot, rubric)
                                        }
                                    }
                                </Draggable>
                            ))}
                        </DragAndDropContainer>
                    </div>
                </div>
            </div>
        </ModalBody>
        
        {/* footer */}
        <ModalFooter>
            <div className={`sb3-flex sb3-justify-end sb3-mb-4`}>
                <div className={`sb3-flex sb3-space-x-3`}>
                    <BorderedButton onClick={() => setHeaderControlOpen(false)} type="button">
                        Cancel
                    </BorderedButton> 
                    <FillButton onClick={handleUpdate} type="button"> 
                        Update
                    </FillButton>
                </div>
            </div>
        </ModalFooter>
    </ModalContainer>
    )
}

export default TableHeaderControlModal
