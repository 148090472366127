import { FaTimes } from "react-icons/fa";
import BorderlessButton from "./Button/BorderlessButton";
import FillButton from "./Button/FillButton"
import ModalBody from "./Modal/ModalBody"
import ModalContainer from "./Modal/ModalContainer"
import ModalFooter from "./Modal/ModalFooter"
import { useAppContext } from "contexts/AppContext";
// import { useLocation, useNavigate } from "react-router-dom";

const ImagePreviewModal = () => {
    const {
      imagePreview,
      setImagePreview
    } = useAppContext()

    return (
    <ModalContainer
      className="!sb3-min-h-56 !sb3-min-w-56"
      isOpen={!!imagePreview}
      setOpen={() => setImagePreview(undefined)}
    >
      <div className="sb3-absolute sb3-top-3 sb3-right-3">
            <BorderlessButton 
                  className="sb3-h-full !sb3-min-w-min sb3-text-xl"
                  onClick={() => setImagePreview(undefined)}
              ><FaTimes/></BorderlessButton>
          </div>
      <ModalBody>
          <img src={imagePreview} alt="Preview" loading="eager" />
      </ModalBody>
          
    </ModalContainer>
    )
  }

export default ImagePreviewModal