import { useAppContext } from "contexts/AppContext";
import { useEffect, useState } from "react";
import NProgress from 'nprogress'
import Navbar from "components/Navbar";
import Cart from "components/Cart";
import Filters from "components/Filters";
import ConfigureModal from "components/ConfigureModal";
import { BUILDER_CONFIG, CATEGORY_ID, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, DEFN_PROJECT, MODEL_CATEGORIES, MODEL_DATA, MODEL_DYNAMIC_FIELDS,  MODEL_SUBMODEL_ID,PAGE_LAYOUT, PAGE_LAYOUTS, RUNTIME_INSTANCE_INSTANCE_ID, THEME_SUBTITLES, THEME_TITLE } from "constants/constants";
import DisabledModal from "components/DisabledModal";
import {  TdisabledModal } from "types/contexts/AppContext";
import Utils from "Services/Utils";
import { TCartItem } from "types/Cart";
import BorderedButton from "components/Button/BorderedButton";
import FillButton from "components/Button/FillButton";
import { FaAngleUp, FaSpinner, FaTimes } from "react-icons/fa";
import Categories from "components/Submodel/Category/Categories";
import { TModel, TProductCategory, TProductCategoryField } from "types";
import { isArray } from "lodash";
import StepsComponent from "components/Steps";
import CloseConfirmModal from "components/CloseModal";
import NodeHelper from "helpers/NodeHelper";
import PagesNavbar from "components/Navbar/PagesNavbar";
import ConfiguratorLayout from "components/Layout/ConfiguratorLayout";
import GuidedSellingLayout from "components/Layout/GuidedSellingLayout";
import ImagePreviewModal from "components/ImagePreviewModal";

const TestTheme = () => {
    const {
        filteredModelSpec,
        uiDefn,
        showConfigurations,
        setShowConfigurations,
        activeSubmodel,
        specs,
        disabledModal,
        setDisabledModal,
        updateInstanceInput,
        isOpen,
        setIsOpen,
        isFilterOpen,
        setFilterOpen,
        cart,
        getCartTotalPrice,
        activePage,
        modelSpec,
        builderSpec,
        loadingToCart,
        isCloseModalOpen,
        setIsCloseModalOpen
    } = useAppContext()

    const [isCartOpen, setIsCartOpen] = useState<boolean>(false)

    useEffect(() => {
        if(!filteredModelSpec && !uiDefn) {
            NProgress.start()
        }
        else{
            NProgress.done()
        }
        return () => {
            NProgress.done()
        }
    }, [filteredModelSpec, uiDefn])

    useEffect(() => {
        (isFilterOpen || isCartOpen) ? document.querySelector("#scrollableDiv")?.classList.add("!overflow-hidden")
        : document.querySelector("#scrollableDiv")?.classList.remove("!overflow-hidden")

        return () => {
            document.querySelector("#scrollableDiv")?.classList.remove("!overflow-hidden")
        };
    }, [isFilterOpen, isCartOpen])

    // let selectedProducts: TCartItem[] = []

    // if(
    //     activeSubmodel?.[MODEL_SUBMODEL_ID] 
    //     && activeSubmodel.instance 
    //     && specs[activeSubmodel?.[MODEL_SUBMODEL_ID]]?.[MODEL_DATA]?.[MODEL_CATEGORIES]?.[0]?.[CATEGORY_ID]
    // ){
    //     selectedProducts = Utils.getSelectedProductsFromCategoryIds(cart, specs[activeSubmodel[MODEL_SUBMODEL_ID]][MODEL_DATA][MODEL_CATEGORIES].map((cat: TProductCategory) => cat[CATEGORY_ID]), activeSubmodel.instance[RUNTIME_INSTANCE_INSTANCE_ID])
    // }

    // const variants = {
    //     initial: { width: 0, opacity: 0 },
    //     animate: { width: '250px', opacity: 1 },
    //     exit: { width: 0, opacity: 0 }
    // }

    // const visibleNodes = activeSubmodel && NodeHelper.filterVisibleNodesUsingRuntime(activeSubmodel.instance[MODEL_DYNAMIC_FIELDS])

    // const isFilterActive = activeSubmodel && isArray(visibleNodes?.visibleFieldIds) 
    //     && (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)?.[MODEL_DATA][MODEL_FIELDS]
    //         .filter((field) => visibleNodes.visibleFieldIds.includes(field[FIELD_ID])).length > 0
    //     && specs[activeSubmodel[MODEL_SUBMODEL_ID]]?.[MODEL_DATA][MODEL_CATEGORIES]
    //         ?.some((cat: TProductCategoryField) => 
    //             (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID]) && 
    //             (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID])
    //         )

    // const isFilterActive = activePage && checkIfPageIsDone(activePage)
    const isFilterActive = true



    return (
        <>
            {
                loadingToCart &&
                <div className="sb3-fixed sb3-h-screen sb3-z-[1003] sb3-w-screen sb3-top-0 sb3-left-0 sb3-bg-white sb3-bg-opacity-70 sb3-text-black sb3-flex sb3-flex-col sb3-items-center sb3-font-bold sb3-justify-center sb3-space-y-5">
                    <p>Stand by while we get your gear together.</p>
                    <FaSpinner className="sb3-animate-spin sb3-text-3xl"/>
                </div>
            }

            <div className="sb3-z-[1001] sb3-sticky sb3-top-0">
                <Navbar/>
            </div>
            <div className="lg:sb3-max-w-7xl sb3-space-y-3 sb3-max-w-3xl sb3-m-auto sb3-py-3 sb3-px-3 sb3-relative">
                {
                    (isCartOpen && activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING) &&
                    <div className="sb3-fixed sb3-h-screen sb3-max-h-screen sb3-overflow-auto lg:sb3-hidden sb3-w-full sb3-z-[2000] sb3-bg-white sb3-top-0 sb3-left-0 sb3-px-2">
                        <div className="sb3-flex sb3-items-center sb3-justify-between sb3-py-2">
                            <h3>Cart</h3>

                            <BorderedButton onClick={() => setIsCartOpen(false)} className="sb3-text-sm lg:sb3-hidden sb3-block">Close</BorderedButton>
                        </div>
                        
                        <Cart/>
                    </div>
                }
                <div className="sb3-flex sb3-justify-between sb3-items-center">
                    <h1 style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_TITLE]}} className="sb3-text-primary">{modelSpec?.[DEFN_PROJECT].ISRName}</h1>

                    { 
                        isOpen !==undefined  && 
                        <BorderedButton onClick={() => setIsCloseModalOpen(true)} className="sb3-text-sm sb3-block !sb3-border-gray-dark hover:!sb3-bg-gray-dark !sb3-text-gray-dark hover:!sb3-text-white sb3-min-w-min sb3-py-0.5 sb3-rounded-none sb3-min-h-min sb3-px-1">
                            <span className="sb3-flex sb3-items-center sb3-space-x-2"><span>Close</span> <FaTimes/></span>
                        </BorderedButton> 
                    }
                </div>

            <div className="">
                <PagesNavbar/>

                
                <main className={`sb3-min-h-content sb3-text-black sb3-max-w-7xl sb3-mx-auto sb3-py-1 sb3-pt-3 sb3-px-3 xl:sb3-px-0 
                    ${activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING && 'lg:sb3-grid sb3-grid-cols-[calc(100%-320px-2.5rem)_320px]'} 
                    sb3-gap-10`}>

                    {/* left side components */}
                    {
                        activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING ? 
                        <GuidedSellingLayout/>:
                        <ConfiguratorLayout/>
                    }

                </main>
                </div>
                <ConfigureModal
                    showModal={showConfigurations}
                    closeModal={() => setShowConfigurations(null)}
                    onConfirm={() => setShowConfigurations(null)}
                    loading={false}
                />

                <DisabledModal
                    showModal={disabledModal}
                    closeModal={() => setDisabledModal(null)}
                    onConfirm={() => updateInstanceInput((disabledModal as TdisabledModal).fieldId, (disabledModal as TdisabledModal).items, disabledModal?.instanceId, true, false, false, disabledModal?.item)}
                    loading={false}
                />
            </div>

            {
                activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING && 
                <div className="sb3-sticky sb3-bottom-0 sb3-left-0 sb3-w-screen lg:sb3-hidden sb3-min-h-12 sb3-bg-white sb3-px-6 sb3-py-3 sb3-border-t sb3-flex sb3-justify-between sb3-items-center">
                    <div>
                        <p className="sb3-text-sm sb3-font-semibold">
                            Your Build {cart.length} Product
                        </p>
                        <p className="!sb3-text-lg sb3-font-semibold">
                            <span>Subtotal: {Utils.formatPrice(getCartTotalPrice())}</span> 
                        </p>
                    </div>

                    <FillButton onClick={() => setIsCartOpen(prev => !prev)}>
                        <span className="sb3-flex sb3-items-center sb3-space-x-2">
                            <FaAngleUp className="!sb3-text-lg"/> 
                            <span>View Build</span>
                        </span>
                    </FillButton>
                </div>
            }

            <CloseConfirmModal
                isOpen={isCloseModalOpen}
                closeModal={() => setIsCloseModalOpen(false)}
                onConfirm={() => setIsOpen?.(false)}
            />

            <ImagePreviewModal/>
        </>
    );
}

export default TestTheme