import AuthManager from "Services/AuthManager";
import AppContextWrapper from "contexts";
// import { domAnimation, LazyMotion } from "framer-motion";
import Login from "pages/Login";
import { PropsWithChildren, SetStateAction } from "react";
import { Navigate } from "react-router-dom";
type MyProps = {
    isOpen: boolean|undefined
    setIsOpen: React.Dispatch<SetStateAction<boolean>>
  }

function PrivateRoute({ children, isOpen, setIsOpen }: PropsWithChildren<MyProps>) {
  return AuthManager.isLoggedIn() ? 
    // <LazyMotion features={domAnimation}>
      <AppContextWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
        {children}
      </AppContextWrapper>
    // </LazyMotion> 
    : <Login/>;
}

export default PrivateRoute