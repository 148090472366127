import React, { useState, useRef, useEffect } from 'react';
import Utils from "Services/Utils";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { BsTextareaResize } from "react-icons/bs";
import { Tooltip } from "react-tooltip";

const useResizable = (minWidth = 200, minHeight = 100, maxWidth = 500, maxHeight = 400) => {
  const [size, setSize] = useState({ width: 300, height: 200 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isResizing, setIsResizing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      e.preventDefault()
      if (!isResizing) return;
      
      const rect = (ref.current as HTMLDivElement).getBoundingClientRect();
    //   const parentRect = (ref.current as HTMLDivElement).offsetParent?.getBoundingClientRect();
      
      const maxAllowedWidth = Math.min(maxWidth, window.innerWidth - rect.left);
      const maxAllowedHeight = Math.min(maxHeight, window.innerHeight - rect.top);
      
      const newWidth = Math.max(minWidth, Math.min(e.clientX - rect.left, maxAllowedWidth));
      const newHeight = Math.max(minHeight, Math.min(e.clientY - rect.top, maxAllowedHeight));
      
      setSize({ width: newWidth + 60, height: newHeight + 30 });

      // Adjust position if tooltip extends beyond right or bottom edge
      const rightOverflow = (rect.left + newWidth) - window.innerWidth;
      const bottomOverflow = (rect.top + newHeight) - window.innerHeight;
      
      setPosition({
        x: rightOverflow > 0 ? -rightOverflow : 0,
        y: bottomOverflow > 0 ? -bottomOverflow : 0
      });
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, minWidth, minHeight, maxWidth, maxHeight]);

  return { size, position, isResizing, setIsResizing, ref };
};

const modifyAnchors = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  
  doc.querySelectorAll('a').forEach(anchor => {
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('rel', 'noopener noreferrer');
  });

  return doc.body.innerHTML;
};

const TooltipInfo = (props: { message: string }) => {
  const tooltipId = Utils.generate_file_name(5);
  const { size, position, setIsResizing, ref } = useResizable();
  const [modifiedMessage, setModifiedMessage] = useState('');

  useEffect(() => {
    setModifiedMessage(modifyAnchors(props.message));
  }, [props.message]);

  return (
    <span>
      <HiOutlineInformationCircle
        className="sb3-text-primary !sb3-text-lg"
        data-tooltip-id={`delta-table-${tooltipId}`}
      />
      <Tooltip
        className={`!sb3-text-white sb3-pointer-events-none sb3-break-normal sb3-z-10 sb3-p-0.5 sb3-whitespace-normal sb3-absolute sb3-shadow-lg`}
        place='right'
        id={`delta-table-${tooltipId}`}
        style={{
          background: "white",
          opacity: 1,
          color: "black",
          width: `${size.width}px`,
          height: `${size.height}px`,
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        border="1px solid #c5c5ca"
        opacity={1}
        events={['click']}
      >
        <div
          ref={ref}
          className="sb3-text-black sb3-overflow-auto sb3-relative sb3-pointer-events-auto"
          style={{
            width: '100%',
            height: '100%',
          }}
        >

          <div className="react-tooltip info-text" dangerouslySetInnerHTML={{ __html: modifiedMessage }} />
          <BsTextareaResize className="sb3-sticky sb3-bottom-0 sb3-left-full sb3-text-black sb3-bg-white sb3-text-xl sb3-cursor-se-resize sb3-pointer-events-auto" onMouseDown={() => setIsResizing(true)}/>
        </div>
          
      </Tooltip>
    </span>
  );
};

export default TooltipInfo;