class AuthManager {
  /**
   * Functions to manage sessions and other auth details
   */
    static __local__session_id = "Compatio.consolidated.sessionid";
    static __local__storage_key = "Compatio.consolidated.SAMS.Logged.user.Object";
    static __local__storage_sams_token_key =
    "Compatio.consolidated.GMS.sams.Object";
    static __local__storage_gms_token_key = "Compatio.consolidated.GMS.Object";
    static __local__storage_query_params = "Compatio.consolidated.SB3.queryParams";
    static __local__storage_pathname = "Compatio.consolidated.SB3.pathname";
    static __local__storage_product_id = "Compatio.consolidated.SB3.productId"; // used to check which product session is currently available

    static setGmsToken(value: string) {
      sessionStorage.setItem(this.__local__storage_gms_token_key, value);
      localStorage.setItem(this.__local__storage_gms_token_key, value);
    }

    static setQueryParams(value: string) {
      sessionStorage.setItem(this.__local__storage_query_params, value);
      localStorage.setItem(this.__local__storage_query_params, value);
    }
    
    static setPathname(value: string) {
      sessionStorage.setItem(this.__local__storage_pathname, value);
      localStorage.setItem(this.__local__storage_pathname, value);
    }

    static setSamsToken(value: string) {
      sessionStorage.setItem(this.__local__storage_sams_token_key, value);
      localStorage.setItem(this.__local__storage_sams_token_key, value);
    }

    static setSessionId(value: string) {
        sessionStorage.setItem(this.__local__session_id, JSON.stringify(value));
        localStorage.setItem(this.__local__session_id, JSON.stringify(value));
    }

    static removeSessionId() {
      sessionStorage.removeItem(this.__local__session_id);
      localStorage.removeItem(this.__local__session_id);
    }

    static setProductId(value: string) {
      sessionStorage.setItem(this.__local__storage_product_id, JSON.stringify(value));
      localStorage.setItem(this.__local__storage_product_id, JSON.stringify(value));
    }

    static removeQueryParams() {
      sessionStorage.removeItem(this.__local__storage_query_params);
      localStorage.removeItem(this.__local__storage_query_params);
    }

    static removePathname() {
      sessionStorage.removeItem(this.__local__storage_pathname);
      localStorage.removeItem(this.__local__storage_pathname);
    }

    static setAuthUser(value: string) {
      try{
        sessionStorage.setItem(this.__local__storage_key, JSON.stringify(value));
        localStorage.setItem(this.__local__storage_key, JSON.stringify(value));
      }
      catch(err) { console.error(err)}
    }

    static isAuthenticated() {
        return this.__local__session_id in localStorage;
    }

    static isLoggedIn() { 
      return this.__local__storage_key in localStorage;
    }

    static isAuthenticatedSession() {
        return this.__local__session_id in sessionStorage;
    }

    static getAuthUser() {
      if(this.isAuthenticatedSession() && this.isAuthenticated()){
        return JSON.parse(sessionStorage.getItem((this.__local__storage_key)) as string)
      }
      else{
        if (this.isAuthenticated()){
          const data = localStorage.getItem(this.__local__storage_key) as string;
          sessionStorage.setItem(this.__local__storage_key, data)
          return JSON.parse(data)
        }
        else{
          this.clearSessionStorage()
        }
      }
      return null;
    }

    static getQueryParams() {
        if(sessionStorage.getItem(this.__local__storage_query_params) != null){
          return sessionStorage.getItem(this.__local__storage_query_params) as string
        }
        else if(localStorage.getItem(this.__local__storage_query_params) != null) {
          const data = localStorage.getItem(this.__local__storage_query_params) as string;
          sessionStorage.setItem(this.__local__storage_query_params, data);
          return data
        }
        return null;
      }

    static getPathname() {
        if(sessionStorage.getItem(this.__local__storage_pathname) != null){
          return sessionStorage.getItem(this.__local__storage_pathname) as string
        }
        else if(localStorage.getItem(this.__local__storage_pathname) != null) {
          const data = localStorage.getItem(this.__local__storage_pathname) as string;
          sessionStorage.setItem(this.__local__storage_pathname, data);
          return data
        }
        return null;
      }

    static getProductId() {
      if(sessionStorage.getItem(this.__local__storage_product_id) != null){
        return sessionStorage.getItem(this.__local__storage_product_id) as string
      }
      else if(localStorage.getItem(this.__local__storage_product_id) != null) {
        const data = localStorage.getItem(this.__local__storage_product_id) as string;
        sessionStorage.setItem(this.__local__storage_product_id, data);
        return data
      }
      return null;
    }

    static getSessionId() {
        if(this.isAuthenticatedSession() && this.isAuthenticated()){
          return JSON.parse(sessionStorage.getItem((this.__local__session_id)) as string)
        }
        else{
          if (this.isAuthenticated()){
            const data = localStorage.getItem(this.__local__session_id) as string;
            sessionStorage.setItem(this.__local__session_id, data)
            return JSON.parse(data)
          }
          else{
            this.clearSessionStorage()
          }
        }
        return null;
      }


    static clearSessionStorage(){
        if(this.isAuthenticatedSession()){
          sessionStorage.removeItem(this.__local__session_id);
        }
        sessionStorage.clear()
    }

    static logoutUser() {
        if (this.isAuthenticated()) {
          localStorage.removeItem(this.__local__session_id);
        }
        if(this.isAuthenticatedSession()){
          sessionStorage.removeItem(this.__local__session_id);
          sessionStorage.removeItem(this.__local__storage_key);
          sessionStorage.removeItem(this.__local__storage_sams_token_key);
          sessionStorage.removeItem(this.__local__storage_gms_token_key);
        }
        if(this.isLoggedIn()) { 
          localStorage.removeItem(this.__local__storage_key);
          localStorage.removeItem(this.__local__storage_sams_token_key);
          localStorage.removeItem(this.__local__storage_gms_token_key);
        }
        // localStorage.clear();
        // sessionStorage.clear();
      }
}

export default AuthManager
