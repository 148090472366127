import { TResponseData, TdoGet, TdoPost} from '../types/Services/ServiceWrapper'

/**
 * Api call functions
 */
const doPost: TdoPost = async (
  url,
  inputdata = null,
  authdata = null,
  token = null,
  isFileUpload = false,
  credentials = true,
  signal = undefined
) => {
  if(!url) throw new Error("Url not found")
  let retObj: TResponseData = {};
  try {
    let authString =
      authdata != null
        ? "Basic " +
          Buffer.from(authdata.email + ":" + authdata.password).toString(
            "base64"
          )
        : null;

    if (authString === null) {
      authString = token != null ? "Bearer " + token : null;
    }
    var formBody = inputdata;
    if (!isFileUpload && inputdata !== null) {
      formBody = JSON.stringify(inputdata);
    }
    var headersObj: HeadersInit = {
      Accept: "application/json",
      "Content-Type": isFileUpload ? "multipart/form-data" : "application/json",
      Authorization: authString ?? "",
    };
    if (isFileUpload) {
      headersObj = {
        Authorization: authString ?? "",
      };
    }

    const options: {[x: string]: any} = {
      method: "POST",
      headers: headersObj,
      body: formBody,
      signal
    }
    if(credentials) options.credentials = "include"

    const rawResponse = await fetch(url, options);
    retObj.data = await rawResponse.json() as any;
    retObj.errorfound = false;
  } catch (error) {
    retObj = { errorfound: true, errorbj: error, data: null };
  } 
  retObj = check_token_expired(retObj);
  return retObj;
};

const doPut: TdoPost = async (
  url,
  inputdata = null,
  authdata = null,
  token = null,
  isFileUpload = false,
  credentials = true
) => {
  let retObj: TResponseData = {};
  try {
    if(!url) throw new Error("url cannot be empty");
    let authString =
      authdata != null
        ? "Basic " +
          Buffer.from(authdata.email + ":" + authdata.password).toString(
            "base64"
          )
        : null;

    if (authString === null) {
      authString = token != null ? "Bearer " + token : null;
    }
    var formBody = inputdata;
    if (!isFileUpload && inputdata !== null) {
      formBody = JSON.stringify(inputdata);
    }

    var headersObj: HeadersInit = {
      Accept: "application/json",
      "Content-Type": isFileUpload ? "multipart/form-data" : "application/json",
      Authorization: authString ?? '',
    };
    if (isFileUpload) {
      headersObj = {
        Authorization: authString ?? '',
      };
    }


    const options: {[x: string]: any} = {
      method: "PUT",
      headers: headersObj,
      body: formBody,
    }
    if(credentials) options.credentials = "include"


    const rawResponse = await fetch(url, options);
    retObj.data = await rawResponse.json();
    retObj.errorfound = false;
  } catch (error) {
    retObj = { errorfound: true, errorbj: error, data: null };
  }
  retObj = check_token_expired(retObj);
  return retObj;
};

const doGet: TdoGet = async (
  url, 
  inputdata, 
  authdata = null, 
  token = null, 
  credentials = true
  ) => {
  let retObj: TResponseData = {};
  try {
    if(!url) throw new Error("url cannot be empty");

    let authString =
      authdata != null
        ? "Basic " +
          Buffer.from(authdata.email + ":" + authdata.password).toString(
            "base64"
          )
        : null;

    if (authString === null) {
      authString = token != null ? "Bearer " + token : null;
    }

    const options: {[x: string]: any} = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authString ?? "",
      },
    }
    if(credentials) options.credentials = "include"

    const rawResponse = await fetch(url, options);
    retObj.data = await rawResponse.json();
    retObj.errorfound = false;
  } catch (error) {
    retObj = { errorfound: true, errorbj: error, data: null };
  } finally {
  }
  retObj = check_token_expired(retObj);
  return retObj;
};

const doDelete: TdoGet = async (
  url, 
  inputdata, 
  authdata = null, 
  token = null,
  credentials = true
  ) => {
  let retObj: TResponseData = {};
  try {
    if(!url) throw new Error("url cannot be empty");

    let authString =
      authdata != null
        ? "Basic " +
          Buffer.from(authdata.email + ":" + authdata.password).toString(
            "base64"
          )
        : null;

    if (authString === null) {
      authString = token != null ? "Bearer " + token : null;
    }
    var formBody = inputdata;
    if (inputdata !== null) {
      formBody = JSON.stringify(inputdata);
    }
    const options: {[x: string]: any} = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authString ?? "",
      },
      body: formBody,
    }
    if(credentials) options.credentials = "include"


    const rawResponse = await fetch(url, options);
    retObj.data = await rawResponse.json();
    retObj.errorfound = false;
  } catch (error) {
    retObj = { errorfound: true, errorbj: error, data: null };
  }
  retObj = check_token_expired(retObj);
  return retObj;
};

const check_token_expired = (ret: any) => {
  if (ret.data != null) {
    if (ret.data.hasOwnProperty("msg"))
      if (ret.data.msg === "Token has expired") {
        ret.errorfound = true;
        (window["bootbox" as any] as any).dialog({
          title: "Session Expired",
          message:
            "<p>Your Session is expired. You will have to login again to the system</p>",
          size: "medium",
          centerVertical: true,
          closeButton: false,
          buttons: {
            ok: {
              label: "OK, I got it",
              className: "btn-info btn-sm",
              callback: function () {
                var redirect_url = get_current_root_url() + "logout";
                window.location.href = redirect_url;
              },
            },
          },
        });
      }
  }
  return ret;
};

function get_current_root_url() {
  return window.location.origin + "/";
}

const ServiceWrapper = { doPost, doGet, doDelete, doPut }
export default ServiceWrapper;
