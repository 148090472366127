import { PropsWithChildren, useCallback, useLayoutEffect, useRef } from "react"
import ReactDOM from 'react-dom';

type MyProps = {
    isOpen: boolean,
    setOpen: Function
    width?: string,
    className?: string
}

const ModalContainer = ({isOpen, setOpen, width, children, className}: PropsWithChildren<MyProps>) => {
    const modalRoot = document.querySelector("#modal-root")
    const modalRef = useRef<HTMLDivElement>(null)

    const handleClickOutside: React.MouseEventHandler<HTMLButtonElement|HTMLDivElement> = useCallback((event) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as HTMLDivElement)
            // if bootbox is needed above modal enable this condition
            //  && !document.querySelector(".bootbox")?.contains(event.target as HTMLDivElement)
        ) {
            setOpen(event);
        }
    }, [modalRef]);

    useLayoutEffect(() => {
        const body = document.querySelector('body')
        if(isOpen) {
            document.addEventListener("mousedown", handleClickOutside as any)
            if(body) body.style.overflow = "hidden"
        }
        else{ 
            document.removeEventListener("mousedown", handleClickOutside as any)
            if(body) body.style.overflow = "auto"
        }

        return () => {
            const body = document.querySelector('body')
            if(body) body.style.overflow = "auto"
            document.removeEventListener("mousedown", handleClickOutside as any);
        };
    }, [handleClickOutside, isOpen])


    if(!isOpen || !modalRoot) return <></>

    return (
        <>
            {
                ReactDOM.createPortal(
                    <div className={`sb3-fixed sb3-w-screen sb3-h-screen sb3-top-0 sb3-left-0 sb3-z-[1040]`}>
                        <div 
                        className={`sb3-absolute sb3-top-0 sb3-left-0 sb3-bg-black sb3-opacity-30 sb3-w-full sb3-h-full`}
                            
                        ></div>
                        <div ref={modalRef} className={`sb3-absolute sb3-m-auto sb3-min-w-[min(500px,_100vw)] 
                            sb3-min-h-[min(200px,_90vh)] sb3-max-h-screen sb3-max-w-screen 
                            sb3-overflow-auto sb3-bg-white sb3-rounded sb3-z-10 
                            sb3-top-1/2 sb3--translate-y-1/2 sb3-left-1/2 sb3--translate-x-1/2 sb3-shadow-sm ${className}`}
                            style={{width: width ?? "auto"}}
                        >
                            {children}
                        </div>
                    </div>,
                    modalRoot
                )
            }
        </>
    )
}

export default ModalContainer