import { PropsWithChildren } from "react"
import { DraggableProvided } from "react-beautiful-dnd"

type MyProps = {
    provided: DraggableProvided
    className?: string
}

const DragAndDropItemWrapper = ({provided, className, children}: PropsWithChildren<MyProps>) => (
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={className}>
        {children}
    </div>
)

export default DragAndDropItemWrapper